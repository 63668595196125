import * as React from 'react';
import NavBar from "../generalComponents/NavBar";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import SalonAdminSettings from "./SalonAdminSettings";
import SalonAdminAnalysis from "./SalonAdminAnalysis";
import SalonSystemActivity from "./SalonSystemActivity";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SalonManagePage() {
    const [value, setValue] = useState(0);
    const [t] = useTranslation('global');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <NavBar/>
            <div className="pageContainer">
                <Box sx={{ width: '100%',}}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label= {t("managePageAdmin.salonAnalysis")} sx={{width:'15vw'}} {...a11yProps(0)} />
                            <Tab label={t("managePageAdmin.salonSettings")} sx={{width:'15vw'}} {...a11yProps(1)} />
                            <Tab label={t("managePageAdmin.salonBusinessInfo")} sx={{width:'15vw'}} {...a11yProps(2)} />
                            <Tab label={t("managePageAdmin.systemActivity")} sx={{width:'15vw'}} {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <SalonAdminAnalysis />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <SalonAdminSettings/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>

                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <SalonSystemActivity />
                    </CustomTabPanel>
                </Box>
            </div>
        </React.Fragment>
    );
}