import React from 'react';
import LoginForm from "./loginPage/LoginForm";
import PrivateRoutes from "../config/PrivateRoutes";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import UserDashboard from "./dashboardPage/UserDashboard";
import Calendar from "./calendar/Calendar";
import Home from "./homePage/Home";
import Settings from "./settingsPage/Settings";
import SalonInfo from "./salonPage/SalonInfo";
import RegisterForm from "./registerPage/RegisterForm";
import PrivateAdminRoutes from "../config/PrivateAdminRoutes";
import SalonManagePage from "./manage/SalonManagePage";
import ResetPasswordForm from "./resetPasswordPage/ResetPasswordForm";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    exact
                    path="/login"
                    element={<LoginForm />}
                />

                <Route
                    exact
                    path="/register/:token"
                    element={<RegisterForm />}
                />

                <Route
                    exact
                    path="/resetPassword/:token"
                    element={<ResetPasswordForm />}
                />

                <Route element={<PrivateRoutes />}>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/dashboard" element={<UserDashboard />} />
                    <Route exact path="/calendar" element={<Calendar />} />
                    <Route exact path="/settings" element={<Settings />} />
                    <Route exact path="/salon" element={<SalonInfo />} />
                    <Route element={<PrivateAdminRoutes />}>
                        <Route exact path="/manage" element={<SalonManagePage />} />
                        <Route exact path="/manage/:submitToken" element={<div>submit</div>} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
export default App;
