import * as React from 'react';
import {API_BASE_URL} from "../../config/config";
import {useEffect, useState} from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateCalendar} from "@mui/x-date-pickers/DateCalendar";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { LineChart } from '@mui/x-charts/LineChart';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AddIcon from '@mui/icons-material/Add';
import {IconButton} from "@mui/material";
import {DataGrid, GridActionsCellItem, GridToolbar} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import {useTranslation} from "react-i18next";
dayjs.extend(isSameOrBefore);

function formatMinutesToHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} h ${minutes} min`;
}
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function getDatesBetween(startDate, endDate) {
    const dateArray = [];
    const dateFormat = 'DD-MM-YYYY';
    let currentDate = dayjs(startDate, dateFormat);

    const end = dayjs(endDate, dateFormat);
    while (currentDate.isSameOrBefore(end, 'day')) {
        dateArray.push(currentDate.format(dateFormat));
        currentDate = currentDate.add(1, 'day');
    }

    return dateArray;
}
export default function SalonAdminSettings() {
    const text = {
        // Root
        noRowsLabel: 'Brak danych',
        noResultsOverlayLabel: 'Nie znaleziono wyników.',

        // Density selector toolbar button text
        toolbarDensity: 'Wysokość rzędu',
        toolbarDensityLabel: 'Wysokość rzędu',
        toolbarDensityCompact: 'Kompakt',
        toolbarDensityStandard: 'Standard',
        toolbarDensityComfortable: 'Komfort',

        // Columns selector toolbar button text
        toolbarColumns: 'Kolumny',
        toolbarColumnsLabel: 'Zaznacz kolumny',

        // Filters toolbar button text
        toolbarFilters: 'Filtry',
        toolbarFiltersLabel: 'Pokaż filtry',
        toolbarFiltersTooltipHide: 'Ukryj filtry',
        toolbarFiltersTooltipShow: 'Pokaż filtry',
        toolbarFiltersTooltipActive: (count) => `Liczba aktywnych filtrów: ${count}`,

        // Quick filter toolbar field
        toolbarQuickFilterPlaceholder: 'Wyszukaj…',
        toolbarQuickFilterLabel: 'Szukaj',
        toolbarQuickFilterDeleteIconLabel: 'Wyczyść',

        // Export selector toolbar button text
        toolbarExport: 'Eksportuj',
        toolbarExportLabel: 'Eksportuj',
        toolbarExportCSV: 'Pobierz jako plik CSV',
        toolbarExportPrint: 'Drukuj',
        toolbarExportExcel: 'Pobierz jako plik Excel',

        // Columns management text
        columnsManagementSearchTitle: 'Szukaj',
        // columnsManagementNoColumns: 'No columns',
        columnsManagementShowHideAllText: 'Pokaż/Ukryj wszystkie',
        columnsManagementReset: 'Resetuj',

        // Filter panel text
        filterPanelAddFilter: 'Dodaj filtr',
        filterPanelRemoveAll: 'Usuń wszystkie',
        filterPanelDeleteIconLabel: 'Usuń',
        filterPanelLogicOperator: 'Operator logiczny',
        filterPanelOperator: 'Operator',
        filterPanelOperatorAnd: 'I',
        filterPanelOperatorOr: 'Lub',
        filterPanelColumns: 'Kolumny',
        filterPanelInputLabel: 'Wartość',
        filterPanelInputPlaceholder: 'Filtrowana wartość',

        // Filter operators text
        filterOperatorContains: 'zawiera',
        filterOperatorEquals: 'równa się',
        filterOperatorStartsWith: 'zaczyna się od',
        filterOperatorEndsWith: 'kończy się na',
        filterOperatorIs: 'równa się',
        filterOperatorNot: 'różne',
        filterOperatorAfter: 'większe niż',
        filterOperatorOnOrAfter: 'większe lub równe',
        filterOperatorBefore: 'mniejsze niż',
        filterOperatorOnOrBefore: 'mniejsze lub równe',
        filterOperatorIsEmpty: 'jest pusty',
        filterOperatorIsNotEmpty: 'nie jest pusty',
        filterOperatorIsAnyOf: 'jest jednym z',
        // 'filterOperator=': '=',
        // 'filterOperator!=': '!=',
        // 'filterOperator>': '>',
        // 'filterOperator>=': '>=',
        // 'filterOperator<': '<',
        // 'filterOperator<=': '<=',

        // Header filter operators text
        headerFilterOperatorContains: 'Zawiera',
        headerFilterOperatorEquals: 'Równa się',
        headerFilterOperatorStartsWith: 'Zaczyna się od',
        headerFilterOperatorEndsWith: 'Kończy się na',
        // headerFilterOperatorIs: 'Is',
        headerFilterOperatorNot: 'Niepuste',
        // headerFilterOperatorAfter: 'Is after',
        // headerFilterOperatorOnOrAfter: 'Is on or after',
        // headerFilterOperatorBefore: 'Is before',
        // headerFilterOperatorOnOrBefore: 'Is on or before',
        // headerFilterOperatorIsEmpty: 'Is empty',
        // headerFilterOperatorIsNotEmpty: 'Is not empty',
        // headerFilterOperatorIsAnyOf: 'Is any of',
        // 'headerFilterOperator=': 'Equals',
        // 'headerFilterOperator!=': 'Not equals',
        // 'headerFilterOperator>': 'Greater than',
        // 'headerFilterOperator>=': 'Greater than or equal to',
        // 'headerFilterOperator<': 'Less than',
        // 'headerFilterOperator<=': 'Less than or equal to',

        // Filter values text
        filterValueAny: 'dowolny',
        filterValueTrue: 'prawda',
        filterValueFalse: 'fałsz',

        // Column menu text
        columnMenuLabel: 'Menu',
        columnMenuShowColumns: 'Pokaż wszystkie kolumny',
        columnMenuManageColumns: 'Zarządzaj kolumnami',
        columnMenuFilter: 'Filtr',
        columnMenuHideColumn: 'Ukryj',
        columnMenuUnsort: 'Anuluj sortowanie',
        columnMenuSortAsc: 'Sortuj rosnąco',
        columnMenuSortDesc: 'Sortuj malejąco',

        // Column header text
        columnHeaderFiltersTooltipActive: (count) => `Liczba aktywnych filtrów: ${count}`,
        columnHeaderFiltersLabel: 'Pokaż filtry',
        columnHeaderSortIconLabel: 'Sortuj',

        // Rows selected footer text
        footerRowSelected: (count) => `Liczba wybranych wierszy: ${count.toLocaleString()}`,

        // Total row amount footer text
        footerTotalRows: 'Łączna liczba wierszy:',

        // Total visible row amount footer text
        footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} z ${totalCount.toLocaleString()}`,

        // Checkbox selection text
        checkboxSelectionHeaderName: 'Pole wyboru',
        checkboxSelectionSelectAllRows: 'Zaznacz wszystkie wiersze',
        checkboxSelectionUnselectAllRows: 'Odznacz wszystkie wiersze',
        checkboxSelectionSelectRow: 'Zaznacz wiersz',
        checkboxSelectionUnselectRow: 'Odznacz wiersz',

        // Boolean cell text
        booleanCellTrueLabel: 'tak',
        booleanCellFalseLabel: 'nie',

        // Actions cell more text
        actionsCellMore: 'więcej',

        // Column pinning text
        pinToLeft: 'Przypnij do lewej',
        pinToRight: 'Przypnij do prawej',
        unpin: 'Odepnij',

        // Tree Data
        treeDataGroupingHeaderName: 'Grupa',
        treeDataExpand: 'pokaż elementy potomne',
        treeDataCollapse: 'ukryj elementy potomne',

        // Grouping columns
        groupingColumnHeaderName: 'Grupa',
        groupColumn: (name) => `Grupuj według ${name}`,
        unGroupColumn: (name) => `Rozgrupuj ${name}`,

        // Master/detail
        // detailPanelToggle: 'Detail panel toggle',
        expandDetailPanel: 'Rozwiń',
        collapseDetailPanel: 'Zwiń',

        // Row reordering text
        rowReorderingHeaderName: 'Porządkowanie wierszy',

        // Aggregation
        aggregationMenuItemHeader: 'Agregacja',
        aggregationFunctionLabelSum: 'suma',
        aggregationFunctionLabelAvg: 'wartość średnia',
        aggregationFunctionLabelMin: 'wartość najmniejsza',
        aggregationFunctionLabelMax: 'wartość największa',
        aggregationFunctionLabelSize: 'rozmiar',
    };
    const [t] = useTranslation('global');
    const [rows, setRows] = React.useState([]);
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'clientName', headerName: t("SalonManager.clientName"), width: 170 },
        { field: 'workerName', headerName: t("SalonManager.workerName"), width: 170 },
        { field: 'procedureName', headerName: t("SalonManager.procedureName"), width: 150 },
        { field: 'date', headerName: t("SalonManager.date"), width: 100 },
        { field: 'hour', headerName: t("SalonManager.hour"), width: 100 },
        { field: 'roomNumber', headerName: t("SalonManager.roomNumber"), width: 100 },
        { field: 'duration', headerName: t("SalonManager.duration"), width: 100 },
        { field: 'price', headerName: t("SalonManager.price"), width: 100 },
        { field: 'isDone',type:'boolean', headerName: t("SalonManager.isDone"), width: 150 },
        { field: 'comment', headerName: t("SalonManager.comment"), width: 150 },
    ];
    const [showAllVisits, setShowAllVisits] = useState(false);
    const [allVisitsToShow, setAllVisitsToShow] = useState([]);
    const api = `${API_BASE_URL}`
    const [dayStart, setDayStart] = useState(dayjs());
    const [dayEnd, setDayEnd] = useState(dayjs().add(7, 'day'));
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
    const date = new Date();
    const [firstTabData, setFirstTabData] = useState([]);
    const [secondTabData, setSecondTabData] = useState([]);
    const [thirdTabData, setThirdTabData] = useState([]);
    const [fourthTabData, setFourthTabData] = useState([]);
    const [allData, setAllData] = useState([]);
    date.setMonth(currentMonth)
    const [datesBetween, setDatesBetween] = useState(getDatesBetween(dayStart.format('DD-MM-YYYY'), dayEnd.format('DD-MM-YYYY')));
    const [clickedWorkerId, setClickedWorkerId] = useState(0);
    const handleShowAllVisits = () => {
        setShowAllVisits(false);
    }
    const changeDate = () => {
        const array = [];
        const array2 = [];
        const array3 = [];
        const array4 = [];
        const date1 = dayStart.format('DD-MM-YYYY');
        const date2 = dayEnd.format('DD-MM-YYYY');
        const newDatesBetween = getDatesBetween(date1, date2); // Directly use the result

        newDatesBetween.forEach(date => {
            let j = 0;
            let i = 0;
            let k = 0;
            let w = 0;
            allData[clickedWorkerId].visits.forEach(visit => {
                let a = date.split('-');
                let d = `${a[2]}-${a[1]}-${a[0]}`; // Reformat the date for comparison
                if (visit.date === d) {
                    i += visit.price;
                    j++;
                }
            });
            allData.forEach(worker => {
                worker.visits.forEach(visit => {
                    let a = date.split('-');
                    let d = `${a[2]}-${a[1]}-${a[0]}`; // Reformat the date for comparison
                    if (visit.date === d) {
                        k += visit.price;
                        w++;
                    }
                })
            })
            array.push(j);
            array2.push(i);
            array3.push(k);
            array4.push(w);
        });

        // Update states at the end to ensure synchronization
        setDatesBetween(newDatesBetween); // Now updating the state after calculations
        setFirstTabData(array); // This ensures the x-axis and series data are in sync
        setSecondTabData(array2);
        setThirdTabData(array3);
        setFourthTabData(array4);
    };
    useEffect(() => {

    }, [firstTabData]);
    const loadVisits = () => {
        const weekDataToSend = {start: dayStart.toDate().toLocaleDateString(), end: dayEnd.toDate().toLocaleDateString()}
        fetch(`${api}/salon/worker/getAllForAnalysis`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            credentials:'include',
            body:JSON.stringify(weekDataToSend),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setAllData(data)
            })
            .catch(error => {
                console.error('Error:', error);
            });

    }
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleClickToShowVisits = () => {
        setShowAllVisits(true);
        var dataToShow = [];
        dataToShow = allData[clickedWorkerId].visits.map(visit => ({
                id: visit.id,
                clientName: visit.clientName,
                comment: visit.comment,
                date: visit.date,
                duration: visit.duration+" min",
                hour: visit.hour,
                price: visit.price+" zł",
                procedureName: visit.procedure.name,
                roomNumber: visit.roomNumber,
                workerName: allData[clickedWorkerId].workerFullName,
                isDone: (visit.isDone === 'true'),
        }));
        setRows(dataToShow)
        setAllVisitsToShow(dataToShow);
    }
    useEffect(() => {
        loadVisits();
    }, [dayStart, dayEnd]);
    



    return (
        <div className="dashBoardContainer">
            <div className="dashBoardBlock" style={{
                width: "59vw",
                padding: "20px",
                minWidth: "20vw",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <div style={{display: "grid", gridTemplateColumns: "11vw 8vw 8vw 8vw 10vw 5vw",marginBottom: "25px"}}>
                    <div className="infoInAnalysis" style={{fontWeight: "bold"}}>Imię i nazwisko</div>
                    <div className="infoInAnalysis" style={{fontWeight: "bold"}}>zarobki </div>
                    <div className="infoInAnalysis" style={{fontWeight: "bold"}}>zarobki oczekiwane</div>
                    <div className="infoInAnalysis" style={{fontWeight: "bold"}}>czas pracy</div>
                    <div className="infoInAnalysis" style={{fontWeight: "bold"}}>czas pracy oczekiwany</div>
                    <div className="infoInAnalysis" style={{fontWeight: "bold"}}>opcje</div>
                </div>
                {allData.map((worker, workerIndex) => (
                    <Button key={workerIndex} sx={{
                        margin: 0, padding: 0, marginBottom: "25px", color: "black"
                    }} onClick={() => {
                        setClickedWorkerId(workerIndex);
                        changeDate();
                    }}>
                        <div className="infoInAnalysisBox" key={workerIndex} style={{
                            backgroundColor: clickedWorkerId === workerIndex ? '#373A40' : '#f5f3f4', // Change this line
                            color: clickedWorkerId === workerIndex ? 'white' : '', // Optional: change text color for better readability
                        }}>
                            <div className="infoInAnalysis" style={{fontWeight: "bold"}}>{worker.workerFullName}</div>
                            <div className="infoInAnalysis">{worker.workerEarnings} zł</div>
                            <div className="infoInAnalysis">{worker.workerEarningsAll} zł</div>
                            <div className="infoInAnalysis">{formatMinutesToHoursAndMinutes(worker.workerHours)}</div>
                            <div className="infoInAnalysis">{formatMinutesToHoursAndMinutes(worker.workerHoursAll)}</div>
                            <div className="infoInAnalysis">
                                <IconButton onClick={handleClickToShowVisits}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </div>
                    </Button>
                ))}

            </div>
            <div className="dashBoardBlock" style={{width: "41vw", padding: "20px", minWidth: "20vw"}}>
                <div className="optionsContainer">
                    <div style={{textAlign: "center", alignItems: "center"}}>
                        <b>Początek</b>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar value={dayStart} onChange={(newValue) => {
                                setDayStart(newValue)

                                changeDate(newValue)
                            }}
                            />
                        </LocalizationProvider>
                    </div>
                    <div style={{textAlign: "center", alignItems: "center"}}>
                        <b>Koniec</b>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar value={dayEnd} onChange={(newValue) => {
                                setDayEnd(newValue)

                                changeDate(newValue);
                            }}/>
                        </LocalizationProvider>
                    </div>
                </div>
                <div>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label= "zabiegi pracownika" sx={{width:'10vw'}} {...a11yProps(0)} />
                            <Tab label="zarobki pracownika" sx={{width:'10vw'}} {...a11yProps(1)} />
                            <Tab label= "zabiegi ogólnie" sx={{width:'10vw'}} {...a11yProps(2)} />
                            <Tab label="zarobki ogólnie" sx={{width:'10vw'}} {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <LineChart
                            xAxis={[{
                                scaleType: 'band',
                                data: datesBetween
                            }]}
                            series={[
                                {
                                    data: firstTabData,
                                },
                            ]}
                            sx={{
                                width: "100%",
                            }}
                            height={400}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <LineChart
                            xAxis={[{
                                scaleType: 'band',
                                data: datesBetween
                            }]}
                            series={[
                                {
                                    data: secondTabData,
                                },
                            ]}
                            sx={{
                                width: "100%",
                            }}
                            height={400}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <LineChart
                            xAxis={[{
                                scaleType: 'band',
                                data: datesBetween
                            }]}
                            series={[
                                {
                                    data: fourthTabData,
                                },
                            ]}
                            sx={{
                                width: "100%",
                            }}
                            height={400}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <LineChart
                            xAxis={[{
                                scaleType: 'band',
                                data: datesBetween
                            }]}
                            series={[
                                {
                                    data: thirdTabData,
                                },
                            ]}
                            sx={{
                                width: "100%",
                            }}
                            height={400}
                        />
                    </CustomTabPanel>
                </div>
            </div>
            <Dialog
                fullWidth="true"
                maxWidth="lg"
                open={showAllVisits}
                onClose={handleShowAllVisits}
            >
                <div style={{height: '60vh', width: '100%'}}>
                    <DataGrid
                        loading={rows.length === 0}
                        rows={rows}
                        columns={columns}
                        slotProps={{
                            pagination: {
                                labelRowsPerPage: "wierszy na stronę",
                            }
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 10},
                            },
                        }}
                        localeText={text}
                        pageSizeOptions={[10, 25, 100]}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                    />
                </div>
            </Dialog>
        </div>
);
}