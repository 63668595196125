import NavBar from "../generalComponents/NavBar";
import Box from "@mui/material/Box";

export default function Settings() {
    return (
        <Box>
            <NavBar/>
            <div className="pageContainer">

            </div>
        </Box>
);
}