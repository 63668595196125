// noinspection JSCheckFunctionSignatures

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import '../../css/style.scss';
import {useTranslation} from "react-i18next";
import {
    Alert,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,
    Typography
} from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import {useEffect, useState} from "react";
import DOMPurify from 'dompurify';
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from '../../config/config';
import {useParams} from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

export default function RegisterForm() {
    const [t] = useTranslation('global');
    let { token } = useParams();
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const api = `${API_BASE_URL}`

    const validateToken = () => () => {
        fetch(`${api}/user/authenticate/validate`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:token,
            credentials: 'include'
        })
            .then(response => {
                setIsLoading(false);
                if (response.ok) {
                    setIsTokenValid(true);
                }
            })
    }
    const[showAlert, setShowAlert] = useState(false);
    const[alertType,setAlertType] = useState('');
    const[alertMessage, setAlertMessage] = useState('');
    const[login, setLogin] = useState('');
    const[password, setPassword] = useState('');
    const[firstName, setFirstName] = useState('');
    const[lastName, setLastName] = useState('');
    const[phoneNumber, setPhoneNumber] = useState('');
    const navigate = useNavigate();
    const handleClick =(e)=>{
        e.preventDefault()
        const sanitizedLogin = DOMPurify.sanitize(login);
        const sanitizedPassword = DOMPurify.sanitize(password);
        const sanitizedFirstName = DOMPurify.sanitize(firstName);
        const sanitizedLastName = DOMPurify.sanitize(lastName);
        const sanitizedPhoneNumber = DOMPurify.sanitize(phoneNumber);
        if (!sanitizedPassword || !sanitizedLogin || !sanitizedFirstName || !sanitizedLastName || !sanitizedPhoneNumber) {
            setAlertMessage(t("loginForm.warningMessage"));
            setAlertType('warning');
            setShowAlert(true);
            return;
        }
        if(sanitizedLogin!==login || sanitizedPassword!==password || sanitizedFirstName!==firstName || sanitizedLastName!==lastName || sanitizedPhoneNumber!==phoneNumber){
            setAlertMessage(t("loginForm.sanitizeDataMessage"));
            setAlertType('warning');
            setShowAlert(true);
            return;
        }
        const registerData = {email:sanitizedLogin,password:sanitizedPassword,firstName:sanitizedFirstName,lastName:sanitizedLastName,phoneNumber:sanitizedPhoneNumber}
        fetch(`${api}/user/authenticate/register/${token}`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify(registerData),
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setAlertType('success')
                setAlertMessage(t("registrationPage.emailConfirmationHasBeenSent"))
                setShowAlert(true);
            })
    }
    useEffect(() => {
        validateToken()();
    }, []);
    // noinspection JSCheckFunctionSignatures
    return (
        <Container>
            {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh', // This makes the Box take up the full height of the viewport
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                isTokenValid ? (
                        <div className="registerBox">
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': {m: 1, width: '25ch'},
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div>
                                    {showAlert &&
                                        <Alert severity={alertType}
                                               variant="outlined"
                                        ><b>{alertMessage}</b></Alert>
                                    }
                                </div>
                                <div className="textField">
                                    <TextField
                                        sx={{
                                            opacity: '1',
                                        }}
                                        required
                                        id="filled-password-input"
                                        variant="filled"
                                        label={t("registrationPage.firstName")}
                                        autoComplete="current-password"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                                <div className="textField">
                                    <TextField
                                        sx={{
                                            opacity: '1',
                                        }}
                                        required
                                        id="filled-password-input"
                                        variant="filled"
                                        label={t("registrationPage.lastName")}
                                        autoComplete="current-password"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                                <div className="textField">
                                    <TextField
                                        sx={{
                                            opacity: '1',
                                        }}
                                        required
                                        type="email"
                                        id="filled-required"
                                        variant="filled"
                                        label={t("registrationPage.email")}
                                        value={login}
                                        onChange={(e) => setLogin(e.target.value)}
                                    />
                                </div>
                                <div className="textField">
                                    <TextField
                                        sx={{
                                            opacity: '1',
                                        }}
                                        required
                                        id="filled-password-input"
                                        variant="filled"
                                        label={t("registrationPage.password")}
                                        type="password"
                                        autoComplete="current-password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <div className="textField">
                                    <TextField
                                        sx={{
                                            opacity: '1',
                                        }}
                                        required
                                        id="filled-password-input"
                                        variant="filled"
                                        label={t("registrationPage.phoneNumber")}
                                        autoComplete="current-password"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                    />
                                </div>
                                <div className="submitButton">
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        size="large"
                                        onClick={handleClick}
                                    >
                                        {t("loginForm.submit")}
                                    </Button>
                                </div>
                            </Box>
                        </div>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh', // This makes the Box take up the full height of the viewport
                        }}
                    >
                        <ErrorIcon sx={{fontSize: 100}}/>
                        <div style={{fontSize: '50px'}}>
                            {t("registrationPage.invalidRegisterLink")}
                        </div>
                    </Box>
                )
            )}

        </Container>
    );
}