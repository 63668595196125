// noinspection JSCheckFunctionSignatures

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import '../../css/style.scss';
import {useTranslation} from "react-i18next";
import {
    Alert,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,
    Typography
} from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import {useEffect, useState} from "react";
import DOMPurify from 'dompurify';
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from '../../config/config';
import {useParams} from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

export default function RegisterForm() {
    const [t] = useTranslation('global');
    let { token } = useParams();
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const api = `${API_BASE_URL}`

    const validateToken = () => () => {
        fetch(`${api}/user/authenticate/reset-password/${token}`,{
            method: 'GET',
            headers:{'Content-Type': 'application/json'},
            credentials: 'include'
        })
            .then(response => {
                setIsLoading(false);
                if (response.ok) {
                    setIsTokenValid(true);
                }
            })
    }
    const[showAlert, setShowAlert] = useState(false);
    const[alertType,setAlertType] = useState('');
    const[alertMessage, setAlertMessage] = useState('');
    const[login, setLogin] = useState('');
    const[password1, setPassword1] = useState('');
    const[password2, setPassword2] = useState('');
    const handleClick =(e)=>{
        e.preventDefault()
        const sanitizedLogin = DOMPurify.sanitize(login);
        const sanitizedPassword1 = DOMPurify.sanitize(password1);
        const sanitizedPassword2 = DOMPurify.sanitize(password2);
        if (!sanitizedPassword1 || !sanitizedPassword2 || !sanitizedLogin || sanitizedPassword1!==sanitizedPassword2) {
            setAlertMessage(t("loginForm.warningMessage"));
            setAlertType('warning');
            setShowAlert(true);
            return;
        }
        if(!sanitizedPassword1===password1|| !sanitizedPassword2===password2 || !sanitizedLogin===login){
            setAlertMessage(t("loginForm.sanitizeDataMessage"));
            setAlertType('warning');
            setShowAlert(true);
            return;
        }
        const resetPasswordData = {email:sanitizedLogin,password:sanitizedPassword1}
        console.log(resetPasswordData);
        fetch(`${api}/user/authenticate/reset-password/${token}`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify(resetPasswordData),
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
        window.location.reload();
    }
    useEffect(() => {
        validateToken()();
    }, []);
    // noinspection JSCheckFunctionSignatures
    return (
        <Container>
            {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh', // This makes the Box take up the full height of the viewport
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                isTokenValid ? (
                    <div className="registerBox">
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': {m: 1, width: '25ch'},
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                {showAlert &&
                                    <Alert severity={alertType}
                                           variant="outlined"
                                    ><b>{alertMessage}</b></Alert>
                                }
                            </div>
                            <div className="textField">
                                <TextField
                                    sx={{
                                        opacity: '1',
                                    }}
                                    required
                                    type="email"
                                    id="filled-required"
                                    variant="filled"
                                    label={t("registrationPage.email")}
                                    value={login}
                                    onChange={(e) => setLogin(e.target.value)}
                                />
                            </div>
                            <div className="textField">
                                <TextField
                                    sx={{
                                        opacity: '1',
                                    }}
                                    required
                                    id="filled-password-input"
                                    variant="filled"
                                    label={t("registrationPage.password")}
                                    type="password"
                                    autoComplete="current-password"
                                    value={password1}
                                    onChange={(e) => setPassword1(e.target.value)}
                                />
                            </div>
                            <div className="textField">
                                <TextField
                                    sx={{
                                        opacity: '1',
                                    }}
                                    required
                                    id="2"
                                    variant="filled"
                                    label={t("registrationPage.password")}
                                    type="password"
                                    autoComplete="current-password"
                                    value={password2}
                                    onChange={(e) => setPassword2(e.target.value)}
                                />
                            </div>
                            <div className="submitButton">
                                <Button
                                    variant="contained"
                                    type="submit"
                                    size="large"
                                    onClick={handleClick}
                                >
                                    {t("loginForm.submit")}
                                </Button>
                            </div>
                        </Box>
                    </div>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh', // This makes the Box take up the full height of the viewport
                        }}
                    >
                        <ErrorIcon sx={{fontSize: 100}}/>
                        <div style={{fontSize: '50px'}}>
                            {t("registrationPage.invalidRegisterLink")}
                        </div>
                    </Box>
                )
            )}

        </Container>
    );
}