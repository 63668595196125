import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useTranslation} from "react-i18next";
import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useEffect, useRef } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SendIcon from '@mui/icons-material/Send';
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import DOMPurify from "dompurify";
import { API_BASE_URL } from '../../config/config';
export default function Tools(props) {
    const [worker, setWorker] = React.useState([]);
    const [client1, setClient1] = React.useState([]);
    const [procedure1, setProcedure1] = React.useState([]);
    const rooms = ["1","2","3","4","5"];
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [room, setRoom] = React.useState('');
    const [procedure, setProcedure] = React.useState('')
    const [price, setPrice] = React.useState('')
    const [duration, setDuration] = React.useState('')
    const[comment, setComment] = React.useState('')
    const [worker1, setWorker1] = React.useState('')
    const [client, setClient] = React.useState('')
    const[visitDate, setVisitDate] = React.useState('')
    const [visitTime, setVisitTime] = React.useState('')
    const api = `${API_BASE_URL}`
    const [sanitizedRoom,setSanitizedRoom] = React.useState(0);
    const theme = useTheme();
    const [sanitizedComment, setSanitizedComment] = React.useState("");
    const handleAction = async () => {
        await props.changeWeek();
    };
    const addVisitForm =(e)=>{

        if (!procedure || !duration || !worker1 || !client || !visitDate || !visitTime || !price) {
            setSnackBarOpen(true);
        }else{
            setOpen(false);
            let day = visitDate.date(); // gets the day of the month
            let month = visitDate.month() + 1; // gets the month (0-11, hence the +1)
            let year = visitDate.year(); // gets the year
            const sanitizedWorker1 = DOMPurify.sanitize(worker1);
            const sanitizedClient = DOMPurify.sanitize(client);
            const sanitizedProcedure = DOMPurify.sanitize(procedure);
            const sanitizedPrice = DOMPurify.sanitize(price);
            const sanitizedDuration = DOMPurify.sanitize(duration);
            setSanitizedRoom(DOMPurify.sanitize(room));
            setSanitizedComment(DOMPurify.sanitize(comment));
            if(!sanitizedRoom){setSanitizedRoom(0);}
            if(!sanitizedComment){setSanitizedComment("");}
            let date = `${year}-${month}-${day}`;
            let time = visitTime.hour()+':'+visitTime.minute();
            const visitAddData = {date:date, time:time, workerNameAndSurname:sanitizedWorker1, clientFirstAndLastName:sanitizedClient, procedureName:sanitizedProcedure, price:sanitizedPrice, duration:sanitizedDuration, room:sanitizedRoom, comment:sanitizedComment}
            console.log(visitAddData);
            fetch(`${api}/salon/visit/add`,{
                method: 'POST',
                headers:{'Content-Type': 'application/json'},
                body:JSON.stringify(visitAddData),
                credentials:'include'
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                })
            handleAction();
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
        fetch(`${api}/salon/worker/getWorkers`,{
            method: 'GET',
            headers:{'Content-Type': 'application/json'},
            credentials:'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const workerNames = data.map(worker => worker.nameandsurname);
                setWorker(workerNames);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        fetch(`${api}/salon/client/getAllClients`,{
            method: 'GET',
            headers:{'Content-Type': 'application/json'},
            credentials:'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const clientNames = data.map(client => client.nameandsurname);
                setClient1(clientNames);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        fetch(`${api}/salon/procedure/getAllProcedures`,{
            method: 'GET',
            headers:{'Content-Type': 'application/json'},
            credentials:'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const procedureInfo = data.map(procedure => ({
                    name: procedure.name,
                    price: procedure.price,
                    duration: procedure.duration,
                    description: procedure.description
                }));
                setProcedure1(procedureInfo);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleClose = () => {
        setOpen(false);
        setComment('');
        setDuration('');
        setPrice('');
    };
    const handleSnackBarClose = () => {
        setSnackBarOpen(false);
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };
    const [t] = useTranslation('global');
    const stopPropagationForTab = (event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };
    return (
        <div>
            <Button
                sx={{
                    [theme.breakpoints.down('768')]: {
                        fontSize: '8px',
                        padding: '5px',
                        margin: 0,
                    },
                }}
                id="demo-customized-button"
                aria-controls={open1 ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick1}
                endIcon={<KeyboardArrowDownIcon/>}
            >
                {t("calendar.tools")}
            </Button>
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open1}
                onClose={handleClose1}
            >
                <MenuItem disableRipple>
                    <div className="toolsAddVisit">
                        <React.Fragment>
                            <Button onClick={handleClickOpen}>
                                <AddIcon/>
                                {t("calendar.addVisit")}
                            </Button>
                            <Dialog
                                fullWidth={fullWidth}
                                maxWidth={maxWidth}
                                open={open}
                                onClose={handleClose}
                                onKeyDown={stopPropagationForTab}
                            >
                                <DialogTitle>{t("calendar.adjustVisit")}</DialogTitle>
                                <FormControl>
                                    <div className="formVisit">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <div className="formVisitComponent">
                                                <DemoContainer components={['DatePicker']} >
                                                    <DatePicker label={t("calendar.date")} sx={{width: '100%'}}
                                                                required
                                                                onChange={(newValue) => {
                                                                    setVisitDate(newValue);
                                                                }}/>
                                                </DemoContainer>
                                                <DemoContainer components={['TimePicker']}>
                                                    <TimePicker
                                                        required
                                                        clearable
                                                        ampm={false}
                                                        label={t("calendar.time")}
                                                        sx={{width: '100%'}}
                                                        onChange={(newValue) => {
                                                            setVisitTime(newValue);
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </div>
                                            <div className="formVisitComponent">
                                                <Autocomplete
                                                    required
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={worker}
                                                    sx={{width: 300, marginTop: 2}}
                                                    renderInput={(params) => <TextField {...params}
                                                                                        label={t("calendar.worker")}/>}
                                                    onChange={(event, newValue) => {
                                                        setWorker1(newValue);
                                                    }}
                                                />

                                            </div>
                                            <div className="formVisitComponent">
                                                <Autocomplete
                                                    freeSolo
                                                    id="free-solo-demo"
                                                    options={client1}
                                                    sx={{width: 300, marginTop: 2}}
                                                    renderInput={(params) => <TextField {...params}
                                                                                        label={t("calendar.client")}/>}
                                                    onChange={(event, newValue) => {
                                                        setClient(newValue);
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        setClient(newInputValue);
                                                    }}
                                                />

                                            </div>
                                            <div className="formVisitComponent">
                                                <Autocomplete
                                                    required
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={procedure1.map(procedure => procedure.name)}
                                                    sx={{width: 300, marginTop: 2}}
                                                    renderInput={(params) => <TextField {...params}
                                                                                        label={t("calendar.procedure")}/>}
                                                    onChange={(event, newValue) => {
                                                        setProcedure(newValue);
                                                        procedure1.forEach(procedure => {
                                                            if (procedure.name === newValue) {
                                                                setPrice(procedure.price)
                                                                setDuration(procedure.duration)
                                                                setComment(procedure.description)
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="formVisitComponent">
                                                <TextField
                                                    sx={{width: 300, marginTop: 2}}
                                                    id="outlined-number"
                                                    label={t("calendar.price")}
                                                    type="number"
                                                    value={price}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(e) => setPrice(e.target.value)}
                                                />
                                                <TextField
                                                    sx={{width: 300, marginTop: 2}}
                                                    required
                                                    id="outlined-number"
                                                    label={t("calendar.duration")}
                                                    value={duration}
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(e) => setDuration(e.target.value)}
                                                />
                                            </div>
                                            <div className="formVisitComponent">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={rooms}
                                                    sx={{marginTop: 2}}
                                                    renderInput={(params) => <TextField {...params}
                                                                                        label={t("calendar.room")}/>}
                                                    onChange={(event, newValue) => {
                                                        setRoom(newValue);
                                                    }}
                                                />
                                            </div>
                                            <div className="formVisitComponent">
                                                <TextField
                                                    sx={{marginTop: 2}}
                                                    id="outlined-multiline-static"
                                                    label={t("calendar.comment")}
                                                    value={comment}
                                                    multiline
                                                    rows={3}
                                                    onChange={(e) => setComment(e.target.value)}
                                                />
                                            </div>
                                            <div className="formVisitComponent"><br/></div>
                                            <Button variant="contained"
                                                    endIcon={<SendIcon/>}
                                                    onClick={addVisitForm}
                                            >
                                                {t("calendar.addVisit")}
                                            </Button>
                                            <div className="formVisitComponent"><br/></div>
                                        </LocalizationProvider>
                                    </div>
                                </FormControl>
                            </Dialog>
                        </React.Fragment>
                    </div>
                </MenuItem>
                {/*<MenuItem disableRipple>*/}
                {/*    <FileCopyIcon/>*/}
                {/*    Pokaż tylko moje wizyty*/}
                {/*</MenuItem>*/}
                <Divider sx={{my: 0.5}}/>
            </Menu>
            <div>
                <Snackbar
                    open={snackBarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackBarClose}
                    message="Uzupełnij wszystkie wymagane pola"
                />
            </div>
        </div>
    );
}