import * as React from 'react';
import {API_BASE_URL} from "../../config/config";
import {DataGrid, GridActionsCellItem, GridToolbar} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import InfoIcon from '@mui/icons-material/Info';
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from "@mui/icons-material/Delete";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
export default function SalonSystemActivity() {
    const [showVisit, setShowVisit] = React.useState(false);
    const [t] = useTranslation('global');
    const [visitDate, setVisitDate] = React.useState("");
    const [hour, setHour] = React.useState("");
    const [duration, setDuration] = React.useState("");
    const [client, setClient] = React.useState("");
    const [worker, setWorker] = React.useState("");
    const [procedure, setProcedure] = React.useState("");
    const text = {
        // Root
        noRowsLabel: 'Brak danych',
        noResultsOverlayLabel: 'Nie znaleziono wyników.',

        // Density selector toolbar button text
        toolbarDensity: 'Wysokość rzędu',
        toolbarDensityLabel: 'Wysokość rzędu',
        toolbarDensityCompact: 'Kompakt',
        toolbarDensityStandard: 'Standard',
        toolbarDensityComfortable: 'Komfort',

        // Columns selector toolbar button text
        toolbarColumns: 'Kolumny',
        toolbarColumnsLabel: 'Zaznacz kolumny',

        // Filters toolbar button text
        toolbarFilters: 'Filtry',
        toolbarFiltersLabel: 'Pokaż filtry',
        toolbarFiltersTooltipHide: 'Ukryj filtry',
        toolbarFiltersTooltipShow: 'Pokaż filtry',
        toolbarFiltersTooltipActive: (count) => `Liczba aktywnych filtrów: ${count}`,

        // Quick filter toolbar field
        toolbarQuickFilterPlaceholder: 'Wyszukaj…',
        toolbarQuickFilterLabel: 'Szukaj',
        toolbarQuickFilterDeleteIconLabel: 'Wyczyść',

        // Export selector toolbar button text
        toolbarExport: 'Eksportuj',
        toolbarExportLabel: 'Eksportuj',
        toolbarExportCSV: 'Pobierz jako plik CSV',
        toolbarExportPrint: 'Drukuj',
        toolbarExportExcel: 'Pobierz jako plik Excel',

        // Columns management text
        columnsManagementSearchTitle: 'Szukaj',
        // columnsManagementNoColumns: 'No columns',
        columnsManagementShowHideAllText: 'Pokaż/Ukryj wszystkie',
        columnsManagementReset: 'Resetuj',

        // Filter panel text
        filterPanelAddFilter: 'Dodaj filtr',
        filterPanelRemoveAll: 'Usuń wszystkie',
        filterPanelDeleteIconLabel: 'Usuń',
        filterPanelLogicOperator: 'Operator logiczny',
        filterPanelOperator: 'Operator',
        filterPanelOperatorAnd: 'I',
        filterPanelOperatorOr: 'Lub',
        filterPanelColumns: 'Kolumny',
        filterPanelInputLabel: 'Wartość',
        filterPanelInputPlaceholder: 'Filtrowana wartość',

        // Filter operators text
        filterOperatorContains: 'zawiera',
        filterOperatorEquals: 'równa się',
        filterOperatorStartsWith: 'zaczyna się od',
        filterOperatorEndsWith: 'kończy się na',
        filterOperatorIs: 'równa się',
        filterOperatorNot: 'różne',
        filterOperatorAfter: 'większe niż',
        filterOperatorOnOrAfter: 'większe lub równe',
        filterOperatorBefore: 'mniejsze niż',
        filterOperatorOnOrBefore: 'mniejsze lub równe',
        filterOperatorIsEmpty: 'jest pusty',
        filterOperatorIsNotEmpty: 'nie jest pusty',
        filterOperatorIsAnyOf: 'jest jednym z',
        // 'filterOperator=': '=',
        // 'filterOperator!=': '!=',
        // 'filterOperator>': '>',
        // 'filterOperator>=': '>=',
        // 'filterOperator<': '<',
        // 'filterOperator<=': '<=',

        // Header filter operators text
        headerFilterOperatorContains: 'Zawiera',
        headerFilterOperatorEquals: 'Równa się',
        headerFilterOperatorStartsWith: 'Zaczyna się od',
        headerFilterOperatorEndsWith: 'Kończy się na',
        // headerFilterOperatorIs: 'Is',
        headerFilterOperatorNot: 'Niepuste',
        // headerFilterOperatorAfter: 'Is after',
        // headerFilterOperatorOnOrAfter: 'Is on or after',
        // headerFilterOperatorBefore: 'Is before',
        // headerFilterOperatorOnOrBefore: 'Is on or before',
        // headerFilterOperatorIsEmpty: 'Is empty',
        // headerFilterOperatorIsNotEmpty: 'Is not empty',
        // headerFilterOperatorIsAnyOf: 'Is any of',
        // 'headerFilterOperator=': 'Equals',
        // 'headerFilterOperator!=': 'Not equals',
        // 'headerFilterOperator>': 'Greater than',
        // 'headerFilterOperator>=': 'Greater than or equal to',
        // 'headerFilterOperator<': 'Less than',
        // 'headerFilterOperator<=': 'Less than or equal to',

        // Filter values text
        filterValueAny: 'dowolny',
        filterValueTrue: 'prawda',
        filterValueFalse: 'fałsz',

        // Column menu text
        columnMenuLabel: 'Menu',
        columnMenuShowColumns: 'Pokaż wszystkie kolumny',
        columnMenuManageColumns: 'Zarządzaj kolumnami',
        columnMenuFilter: 'Filtr',
        columnMenuHideColumn: 'Ukryj',
        columnMenuUnsort: 'Anuluj sortowanie',
        columnMenuSortAsc: 'Sortuj rosnąco',
        columnMenuSortDesc: 'Sortuj malejąco',

        // Column header text
        columnHeaderFiltersTooltipActive: (count) => `Liczba aktywnych filtrów: ${count}`,
        columnHeaderFiltersLabel: 'Pokaż filtry',
        columnHeaderSortIconLabel: 'Sortuj',

        // Rows selected footer text
        footerRowSelected: (count) => `Liczba wybranych wierszy: ${count.toLocaleString()}`,

        // Total row amount footer text
        footerTotalRows: 'Łączna liczba wierszy:',

        // Total visible row amount footer text
        footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} z ${totalCount.toLocaleString()}`,

        // Checkbox selection text
        checkboxSelectionHeaderName: 'Pole wyboru',
        checkboxSelectionSelectAllRows: 'Zaznacz wszystkie wiersze',
        checkboxSelectionUnselectAllRows: 'Odznacz wszystkie wiersze',
        checkboxSelectionSelectRow: 'Zaznacz wiersz',
        checkboxSelectionUnselectRow: 'Odznacz wiersz',

        // Boolean cell text
        booleanCellTrueLabel: 'tak',
        booleanCellFalseLabel: 'nie',

        // Actions cell more text
        actionsCellMore: 'więcej',

        // Column pinning text
        pinToLeft: 'Przypnij do lewej',
        pinToRight: 'Przypnij do prawej',
        unpin: 'Odepnij',

        // Tree Data
        treeDataGroupingHeaderName: 'Grupa',
        treeDataExpand: 'pokaż elementy potomne',
        treeDataCollapse: 'ukryj elementy potomne',

        // Grouping columns
        groupingColumnHeaderName: 'Grupa',
        groupColumn: (name) => `Grupuj według ${name}`,
        unGroupColumn: (name) => `Rozgrupuj ${name}`,

        // Master/detail
        // detailPanelToggle: 'Detail panel toggle',
        expandDetailPanel: 'Rozwiń',
        collapseDetailPanel: 'Zwiń',

        // Row reordering text
        rowReorderingHeaderName: 'Porządkowanie wierszy',

        // Aggregation
        aggregationMenuItemHeader: 'Agregacja',
        aggregationFunctionLabelSum: 'suma',
        aggregationFunctionLabelAvg: 'wartość średnia',
        aggregationFunctionLabelMin: 'wartość najmniejsza',
        aggregationFunctionLabelMax: 'wartość największa',
        aggregationFunctionLabelSize: 'rozmiar',
    };
    const api = `${API_BASE_URL}`
    const [rows, setRows] = React.useState([]);
    const [visits, setVisits] = React.useState([]);
    const formatTime = (dateString) => {
        const date = new Date(dateString);

        // Extract hours and minutes in 24-hour format
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Format the time as HH:MM
        const formattedTime = `${hours}:${minutes}`;

        return formattedTime;
    };
    const handleClose = () => {
        setShowVisit(false);
    }

    const loadData = () => {
        fetch(`${api}/salon/systemActivity/getAll`,{
            method: 'GET',
            headers:{'Content-Type': 'application/json'},
            credentials:'include'
        })
            .then(response => {
                if (!response.ok) {
                    console.log(response);                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                const allData = data.map(activity => ({
                    id: activity.id,
                    activity: activity.activity,
                    comment: activity.comment,
                    date: activity.date,
                    time: formatTime(activity.time),
                    workerName: activity.workernameandsurname,
                    isSubmitted: activity.isSubmitted,
                }));
                setVisits(data);
                setRows(allData);
            });
    }
    React.useEffect(() => {
        loadData();
    }, []);
    const handleShowVisitClick = (params) => () => {
        visits.forEach(visit => {
            if(visit.id === params.row.id){
                setDuration(visit.durationOfVisit);
                setHour(formatTime(visit.timeOfVisit));
                setVisitDate(visit.date);
                setProcedure(visit.procedureName);
                setWorker(visit.workerName);
                setClient(visit.clientName);
            }
        })
        setShowVisit(true);

    }
    const handleDeleteClick = (params) => () => {
        fetch(`${api}/salon/systemActivity/delete/${params.row.id}`,{
            method: 'GET',
            headers:{'Content-Type': 'application/json'},
            credentials:'include'
        }).then(response => {
            loadData();
        })
    }
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'activity', headerName: "aktywność", width: 160 },
        { field: 'comment', headerName: "komentarz", width: 300 },
        { field: 'time', headerName: "godzina", width: 100 },
        { field: 'date', headerName: "data", width: 150 },
        { field: 'workerName', headerName: "imię pracownika", width: 200 },
        { field: 'isSubmitted',type:'boolean', headerName: "czy zatwierdzono", width: 150 },
        {
            field: 'actions',
            type: 'actions',
            headerName: t("SalonManager.actions"),
            width: 110,
            cellClassName: 'actions',
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<InfoIcon/>}
                        label="edit"
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={() => handleShowVisitClick(params)()}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="edit"
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={() => handleDeleteClick(params)()}
                    />,
                ];
            },
        },
    ];
    return (
        <div style={{height: '60vh', width: '100%'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                slotProps={{
                    pagination: {
                        labelRowsPerPage: "wierszy na stronę",
                    }
                }}
                initialState={{
                    pagination: {
                        paginationModel: {page: 0, pageSize: 10},
                    },
                }}
                localeText={text}
                pageSizeOptions={[10, 25, 100]}
                slots={{
                    toolbar: GridToolbar,
                }}
            />
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={showVisit}
            >
                <DialogTitle sx={{m: 5, p: 0, width: '300px', textAlign: 'center'}} id="customized-dialog-title">
                    {t("calendar.visitDetails")}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {t("calendar.visitDate")}{visitDate}
                    </Typography>
                    <Typography gutterBottom>
                        {t("calendar.visitTime")}{hour}
                    </Typography>
                    <Typography gutterBottom>
                        {t("calendar.visitDuration")}{duration}min
                    </Typography>
                    <Typography gutterBottom>
                        {t("calendar.visitClient")}{client}
                    </Typography>
                    <Typography gutterBottom>
                        {t("calendar.visitEmployee")}{worker}
                    </Typography>
                    <Typography gutterBottom>
                        {t("calendar.visitProcedure")}{procedure}
                    </Typography>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}