import * as React from 'react';
import NavBar from "../generalComponents/NavBar";
import Box from "@mui/material/Box";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {IconButton} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Tools from "./Tools";
import {API_BASE_URL} from "../../config/config";
import DashboardVisit from "../dashboardPage/DashboardVisit";
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
export default function Calendar() {
    //----------------------Add visit dialog----------------------

    // ----------------------------------------------------------
    const [t] = useTranslation('global');
    const theme = useTheme();
    const getMonday = (date) => {
        date = new Date(date);
        let day = date.getDay(),
            diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
        return new Date(date.setDate(diff));
    }
    const getWorkerTimes = (number, workerClicked,data) => {
        try{
            const worker = data.workerAvailabilities[number].find(workers => workers.workerName === workerClicked);
            var s = worker.startTime.split(":");
            var e = worker.endTime.split(":");
            return s[0]+":"+s[1]+" - "+e[0]+":"+e[1];
        }catch (error){
            return "07:00 - 20:00"
        }
    }
    const api = `${API_BASE_URL}`
    const[mondayTimes, setMondayTimes] = useState("")
    const[tuesdayTimes, setTuesdayTimes] = useState("")
    const[wednesdayTimes, setWednesdayTimes] = useState("")
    const[thursdayTimes, setThursdayTimes] = useState("")
    const[fridayTimes, setFridayTimes] = useState("")
    const[saturdayTimes, setSaturdayTimes] = useState("")
    const[sundayTimes, setSundayTimes] = useState("")
    const [currentWeekStart, setCurrentWeekStart] = useState(getMonday(new Date()));
    const [currentWorker, setCurrentWorker] = useState("")
    const [workers, setWorkers] = useState([]);
    const [currentWeekEnd, setCurrentWeekEnd] = useState(new Date(currentWeekStart.getTime() + 6 * 24 * 60 * 60 * 1000));
    const [currentWeek, setCurrentWeek] = useState(currentWeekStart.toLocaleDateString()+" - "+currentWeekEnd.toLocaleDateString());
    const [mondayVisits, setMondayVisits] = useState([]);
    const [tuesdayVisits, setTuesdayVisits] = useState([]);
    const [wednesdayVisits, setWednesdayVisits] = useState([]);
    const [thursdayVisits, setThursdayVisits] = useState([]);
    const [fridayVisits, setFridayVisits] = useState([]);
    const [saturdayVisits, setSaturdayVisits] = useState([]);
    const [sundayVisits, setSundayVisits] = useState([]);
    const [workerInfo, setWorkerInfo] = useState([]);
    const updateHours = (st,en) => {
        fetch(`${api}/salon/workerAvailability/getWorkersAndTheirAvailabilityForPeriod`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            credentials:'include',
            body: JSON.stringify({start: st.toLocaleDateString(), end: en.toLocaleDateString()})
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(data => {
            setWorkerInfo(data);
            setMondayTimes(getWorkerTimes(0,currentWorker,data));
            setTuesdayTimes(getWorkerTimes(1,currentWorker,data));
            setWednesdayTimes(getWorkerTimes(2,currentWorker,data));
            setThursdayTimes(getWorkerTimes(3,currentWorker,data));
            setFridayTimes(getWorkerTimes(4,currentWorker,data));
            setSaturdayTimes(getWorkerTimes(5,currentWorker,data));
            setSundayTimes(getWorkerTimes(6,currentWorker,data));

        })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    const fetchAllWorkers = () => {
        fetch(`${api}/salon/workerAvailability/getWorkersAndTheirAvailabilityForPeriod`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            credentials:'include',
            body: JSON.stringify({start: currentWeekStart.toLocaleDateString(), end: currentWeekEnd.toLocaleDateString()})
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
            .then(data => {
                setWorkerInfo(data);
                const workerNames = data.workers.map(worker => ({ id: worker.id, lastName: worker.nameandsurname, color:worker.color,borderColor:worker.borderColor, availabilityStart: "07:00",availabilityEnd: "20:00" }));
                const workerLastNames = workerNames.map(worker => worker.lastName);
                setWorkers(workerLastNames);
                setCurrentWorker(workerLastNames[0]);
                setMondayTimes(getWorkerTimes(0,workerLastNames[0],data));
                setTuesdayTimes(getWorkerTimes(1,workerLastNames[0],data));
                setWednesdayTimes(getWorkerTimes(2,workerLastNames[0],data));
                setThursdayTimes(getWorkerTimes(3,workerLastNames[0],data));
                setFridayTimes(getWorkerTimes(4,workerLastNames[0],data));
                setSaturdayTimes(getWorkerTimes(5,workerLastNames[0],data));
                setSundayTimes(getWorkerTimes(6,workerLastNames[0],data));

            })
            .catch(error => {
                console.error('Error:', error);
            });

    }
    useEffect(() => {
        fetchAllWorkers();
    }, [])
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    async function sleepToLoadVisits(){
        await sleep(400);
        changeWeek(0)();
    }
    const changeWeek = (direction) => () => {
        let newWeekStart = new Date(currentWeekStart.getTime() + direction * 7 * 24 * 60 * 60 * 1000);
        setCurrentWeekStart(newWeekStart);
        let newWeekEnd = new Date(newWeekStart.getTime() + 6 * 24 * 60 * 60 * 1000);
        setCurrentWeekEnd(newWeekEnd);
        updateHours(newWeekStart,newWeekEnd);
        const weekDataToSend = {start: newWeekStart.toLocaleDateString(), end: newWeekEnd.toLocaleDateString()}
        fetch(`${api}/salon/visit/giveAllFromWeek`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            credentials:'include',
            body: JSON.stringify(weekDataToSend)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const allVisits = data.map(visit => ({id:visit.id,
                    borderColor: visit.borderColor,clientName: visit.clientName,color:visit.color,
                    comment:visit.comment,date:visit.date,duration:visit.duration,hour:visit.hour,
                    procedureName: visit.procedureName,roomNumber:visit.roomNumber,workerName:visit.workerName,
                    completed:visit.completed,phoneNumber:visit.phoneNumber,price:visit.price
                }));
                setMondayVisits([]);
                setTuesdayVisits([]);
                setWednesdayVisits([]);
                setThursdayVisits([]);
                setFridayVisits([]);
                setSaturdayVisits([]);
                setSundayVisits([]);
                for(let v of allVisits){
                    if(new Date(v.date).getDate() === new Date(newWeekStart.getTime()).getDate() && v.workerName === currentWorker){
                        const newElement =
                            <DashboardVisit
                                key={v.id}
                                borderColor={v.borderColor}
                                color={v.color}
                                startTime={v.hour}
                                client={v.clientName}
                                worker={v.workerName}
                                procedure={v.procedureName}
                                comment={v.comment}
                                room={v.roomNumber}
                                durationInMinutes={v.duration}
                                price={v.price}
                                date={v.date}
                                completed={v.completed}
                                id={v.id}
                                onActionComplete={sleepToLoadVisits}
                                phoneNumber={v.phoneNumber}
                            />
                        setMondayVisits(prevVisits => [...prevVisits, newElement]);
                    }
                    if(new Date(v.date).getDate() === new Date(newWeekStart.getTime() + 24 * 60 * 60 * 1000).getDate() && v.workerName === currentWorker){
                        const newElement =
                            <DashboardVisit
                                key={v.id}
                                borderColor={v.borderColor}
                                color={v.color}
                                startTime={v.hour}
                                client={v.clientName}
                                worker={v.workerName}
                                procedure={v.procedureName}
                                comment={v.comment}
                                room={v.roomNumber}
                                durationInMinutes={v.duration}
                                price={v.price}
                                date={v.date}
                                completed={v.completed}
                                id={v.id}
                                onActionComplete={sleepToLoadVisits}
                                phoneNumber={v.phoneNumber}
                            />
                        setTuesdayVisits(prevVisits => [...prevVisits, newElement]);
                    }
                    if(new Date(v.date).getDate() === new Date(newWeekStart.getTime() + 2 * 24 * 60 * 60 * 1000).getDate() && v.workerName === currentWorker){
                        const newElement =
                            <DashboardVisit
                                key={v.id}
                                borderColor={v.borderColor}
                                color={v.color}
                                startTime={v.hour}
                                client={v.clientName}
                                worker={v.workerName}
                                procedure={v.procedureName}
                                comment={v.comment}
                                room={v.roomNumber}
                                durationInMinutes={v.duration}
                                price={v.price}
                                date={v.date}
                                completed={v.completed}
                                id={v.id}
                                onActionComplete={sleepToLoadVisits}
                                phoneNumber={v.phoneNumber}
                            />
                        setWednesdayVisits(prevVisits => [...prevVisits, newElement]);
                    }
                    if(new Date(v.date).getDate() === new Date(newWeekStart.getTime() +3* 24 * 60 * 60 * 1000).getDate() && v.workerName === currentWorker){
                        const newElement =
                            <DashboardVisit
                                key={v.id}
                                borderColor={v.borderColor}
                                color={v.color}
                                startTime={v.hour}
                                client={v.clientName}
                                worker={v.workerName}
                                procedure={v.procedureName}
                                comment={v.comment}
                                room={v.roomNumber}
                                durationInMinutes={v.duration}
                                price={v.price}
                                date={v.date}
                                completed={v.completed}
                                id={v.id}
                                onActionComplete={sleepToLoadVisits}
                                phoneNumber={v.phoneNumber}
                            />
                        setThursdayVisits(prevVisits => [...prevVisits, newElement]);
                    }
                    if(new Date(v.date).getDate() === new Date(newWeekStart.getTime() +4* 24 * 60 * 60 * 1000).getDate() && v.workerName === currentWorker){
                        const newElement =
                            <DashboardVisit
                                key={v.id}
                                borderColor={v.borderColor}
                                color={v.color}
                                startTime={v.hour}
                                client={v.clientName}
                                worker={v.workerName}
                                procedure={v.procedureName}
                                comment={v.comment}
                                room={v.roomNumber}
                                durationInMinutes={v.duration}
                                price={v.price}
                                date={v.date}
                                completed={v.completed}
                                id={v.id}
                                onActionComplete={sleepToLoadVisits}
                                phoneNumber={v.phoneNumber}
                            />
                        setFridayVisits(prevVisits => [...prevVisits, newElement]);
                    }
                    if(new Date(v.date).getDate() === new Date(newWeekStart.getTime() +5* 24 * 60 * 60 * 1000).getDate() && v.workerName === currentWorker){
                        const newElement =
                            <DashboardVisit
                                key={v.id}
                                borderColor={v.borderColor}
                                color={v.color}
                                startTime={v.hour}
                                client={v.clientName}
                                worker={v.workerName}
                                procedure={v.procedureName}
                                comment={v.comment}
                                room={v.roomNumber}
                                durationInMinutes={v.duration}
                                price={v.price}
                                date={v.date}
                                completed={v.completed}
                                id={v.id}
                                onActionComplete={sleepToLoadVisits}
                                phoneNumber={v.phoneNumber}
                            />
                        setSaturdayVisits(prevVisits => [...prevVisits, newElement]);
                    }
                    if(new Date(v.date).getDate() === new Date(newWeekStart.getTime() +6* 24 * 60 * 60 * 1000).getDate() && v.workerName === currentWorker){
                        const newElement =
                            <DashboardVisit
                                key={v.id}
                                borderColor={v.borderColor}
                                color={v.color}
                                startTime={v.hour}
                                client={v.clientName}
                                worker={v.workerName}
                                procedure={v.procedureName}
                                comment={v.comment}
                                room={v.roomNumber}
                                durationInMinutes={v.duration}
                                price={v.price}
                                date={v.date}
                                completed={v.completed}
                                id={v.id}
                                onActionComplete={sleepToLoadVisits}
                                phoneNumber={v.phoneNumber}
                            />
                        setSundayVisits(prevVisits => [...prevVisits, newElement]);
                    }
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        setCurrentWeek(newWeekStart.toLocaleDateString()+" - "+newWeekEnd.toLocaleDateString());
    }
    useEffect(() => {
        changeWeek(0)();
    }, [currentWorker]);
    const hours = [];
    for (let i = 7; i <= 20; i++) {
        hours.push(<div className="hour">{i}:00</div>);
        hours.push(<div className="hour">{i}:30</div>);
    }
    const getDayDate = (startDate, dayOffset) => {
        const date = new Date(startDate);
        date.setDate(date.getDate() + dayOffset);
        return date.toLocaleDateString();
    };
    useEffect(() => {
        document.addEventListener('gesturestart', function (e) {
            e.preventDefault();
        });
    })
    return (
        <Box>
            <CssBaseline />
            <NavBar />
            <div className="pageContainer">
                <div className="pageHeader">
                    <div
                        className="selectWorkerCalendar">
                        Kalendarz pracownika: {currentWorker}
                        <FormControl sx={{
                            width: 100, marginLeft: 20,
                                [theme.breakpoints.down('sm')]: {
                                    width: 60, // Custom width for small screens
                                    marginLeft: 0,
                                    fontSize: '10px',
                                },

                        }}>
                            <InputLabel id="demo-simple-select-label">Pracownik</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Pracownik"
                                onChange={(event) => {
                                    const selectedWorker = event.target.value;
                                    setCurrentWorker(selectedWorker);

                                    setMondayTimes(getWorkerTimes(0,selectedWorker,workerInfo));
                                    setTuesdayTimes(getWorkerTimes(1,selectedWorker,workerInfo));
                                    setWednesdayTimes(getWorkerTimes(2,selectedWorker,workerInfo));
                                    setThursdayTimes(getWorkerTimes(3,selectedWorker,workerInfo));
                                    setFridayTimes(getWorkerTimes(4,selectedWorker,workerInfo));
                                    setSaturdayTimes(getWorkerTimes(5,selectedWorker,workerInfo));
                                    setSundayTimes(getWorkerTimes(6,selectedWorker,workerInfo));
                                }}
                            >
                                {workers.map(worker => (
                                    <MenuItem value={worker}>
                                        {worker}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="weekInfo">
                        <IconButton
                            onClick={changeWeek(-1)}
                        >
                            <ArrowBackIcon
                                sx={{
                                    [theme.breakpoints.down(768)]: {
                                        fontSize: 'small'
                                    },
                                }}
                            />
                        </IconButton>
                        <Box
                            sx={{
                                [theme.breakpoints.down(768)]: {
                                    display: 'none'
                                },
                            }}
                        >
                            {currentWeek}
                        </Box>

                        <IconButton
                            onClick={changeWeek(1)}
                        >
                        <ArrowForwardIcon
                                sx={{
                                    [theme.breakpoints.down(768)]: {
                                        fontSize: 'small'
                                    },
                                }}
                            />
                        </IconButton>
                    </div>
                    <div className="tools">
                        <Tools changeWeek={sleepToLoadVisits}/>
                    </div>
                </div>
                <div className="pageContent">
                    <div className="Calendar">
                        <div className="dayName" style={{gridColumn: 2}}>
                            Pn, {currentWeekStart.toLocaleDateString()}<br/>
                            {mondayTimes}
                        </div>
                        <div className="dayName" style={{gridColumn: 3}}>
                            Wt, {getDayDate(currentWeekStart, 1)}<br/>
                            {thursdayTimes}
                        </div>
                        <div className="dayName" style={{gridColumn: 4}}>
                            Śr, {getDayDate(currentWeekStart, 2)}<br/>
                            {wednesdayTimes}
                        </div>
                        <div className="dayName" style={{gridColumn: 5}}>
                            Czw, {getDayDate(currentWeekStart, 3)}<br/>
                            {thursdayTimes}
                        </div>
                        <div className="dayName" style={{gridColumn: 6}}>
                            Pt, {getDayDate(currentWeekStart, 4)}<br/>
                            {fridayTimes}
                        </div>
                        <div className="dayName" style={{gridColumn: 7}}>
                            Sb, {getDayDate(currentWeekStart, 5)}<br/>
                            {saturdayTimes}
                        </div>
                        <div className="dayName" style={{gridColumn: 8}}>
                            Ndz, {getDayDate(currentWeekStart, 6)}<br/>
                            {sundayTimes}
                        </div>
                        <div className="hours">
                            {hours}
                        </div>
                        <div className="day" style={{gridColumn: 2}}>
                            {
                                Array.from({length: 840}, (_, index) => (

                                    <div key={index} className="gridBackground">
                                    </div>
                                ))
                            }
                            <div className="monday">
                                {mondayVisits}
                            </div>
                        </div>
                        <div className="day" style={{gridColumn: 3}}>
                            {
                                Array.from({length: 840}, (_, index) => (

                                    <div key={index} className="gridBackground">
                                    </div>
                                ))
                            }
                            <div className="tuesday">
                                {tuesdayVisits}
                            </div>
                        </div>
                        <div className="day" style={{gridColumn: 4}}>
                            {
                                Array.from({length: 840}, (_, index) => (

                                    <div key={index} className="gridBackground">
                                    </div>
                                ))
                            }
                            <div className="wednesday">
                                {wednesdayVisits}
                            </div>
                        </div>
                        <div className="day" style={{gridColumn: 5}}>
                            {
                                Array.from({length: 840}, (_, index) => (

                                    <div key={index} className="gridBackground">
                                    </div>
                                ))
                            }
                            <div className="thursday">
                                {thursdayVisits}
                            </div>
                        </div>
                        <div className="day" style={{gridColumn: 6}}>
                            {
                                Array.from({length: 840}, (_, index) => (

                                    <div key={index} className="gridBackground">
                                    </div>
                                ))
                            }
                            <div className="friday">
                                {fridayVisits}
                            </div>
                        </div>
                        <div className="day" style={{gridColumn: 7}}>
                            {
                                Array.from({length: 840}, (_, index) => (

                                    <div key={index} className="gridBackground">
                                    </div>
                                ))
                            }
                            <div className="saturday">
                                {saturdayVisits}
                            </div>
                        </div>
                        <div className="day" style={{gridColumn: 8, border: "none"}}>
                            {
                                Array.from({length: 840}, (_, index) => (

                                    <div key={index} className="gridBackground">
                                    </div>
                                ))
                            }
                            <div className="sunday">
                                {sundayVisits}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );
}