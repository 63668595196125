import * as React from 'react';
import NavBar from "../generalComponents/NavBar";
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import {useEffect, useRef, useState} from "react";
import QueueIcon from '@mui/icons-material/Queue';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { deepOrange } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import {API_BASE_URL} from "../../config/config";
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import PageviewIcon from '@mui/icons-material/Pageview';
import DashboardVisit from "./DashboardVisit";
import Tools from "../calendar/Tools";
import Dialog from "@mui/material/Dialog";
import {styled, useTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from "@mui/material/FormControl";
import DOMPurify from "dompurify";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Snackbar from "@mui/material/Snackbar";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
export default function UserDashboard() {
    const [t] = useTranslation('global');

    const handleSnackBarClose = () => {
        setSnackBarOpen(false);
    }
    const hours = [];
    for (let i = 7; i <= 20; i++) {
        hours.push(<div className="dashBoardHour">{i}:00</div>);
    }
    const theme = useTheme();
    const [clientName, setClientName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientPhoneNumber, setClientPhoneNumber] = useState('');
    const [clientDescription, setClientDescription] = useState('');

    const [addOneAfterAnother, setAddOneAfterAnother] = useState(false);
    const [addClient, setAddClient] = useState(false);
    const [editWorkTime, setEditWorkTime] = useState(false);
    const [addVisit, setAddVisit] = useState(false);
    const [pageDayView, setPageDayView] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const changeEditWorkTime = () => {
        if(!editWorkTime){
            setWorker1("");
        }
        setAddVisit(false);
        setAddClient(false);
        setEditWorkTime(!editWorkTime);
    }
    const changeAddOneAfterAnother = () => {
        if(addOneAfterAnother){
            setVisitTime(dayjs().hour(12).minute(0));
            setWorker1("");
            setProcedure("");
            setClient("");
            setDuration(0);
            setPrice(0);
            setRoom(1)
            setComment("");
        }
        setAddOneAfterAnother(!addOneAfterAnother);}
    const changeAddClient = () =>{
        setAddVisit(false);
        setEditWorkTime(false);
        setAddClient(!addClient);
    }
    const changeAddVisit = () => {
        if(!addVisit){
            setWorker1("");
        }
        setAddClient(false);
        setEditWorkTime(false);
        setAddVisit(!addVisit);}
    const seePageDayView = () => {setPageDayView(!pageDayView);}
    const OptionInProfile = (props) => {const text = props.text;
        const backgroundColor = props.param ? '#DDDDDD' : '#EEEEEE';
        const Icon = props.icon;
        return (
            <div className="optionInProfile" style={{ backgroundColor }}>
                <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                    <button style={{
                        background: "none",
                        color: "inherit",
                        border: "none",
                        padding: 0,
                        font: "inherit",
                        cursor: "pointer",
                        outline: "inherit",
                        width: "100%",
                        height: "100%"
                    }} onClick={() => {
                        props.onClick();
                    }}
                    >
                        <div><Icon
                            fontSize="large"
                            sx={{
                            [theme.breakpoints.down(768)]: {
                                fontSize: 'medium'
                            },
                        }}
                        /></div>
                        <Box sx={{
                            [theme.breakpoints.down(768)]: {
                            fontSize: '8px'
                        },
                        }}>{text}</Box>
                    </button>
                </div>
            </div>
        );
    }



    const [currentDay, setCurrentDay] = useState(dayjs());
    const navigate = useNavigate();
    const api = `${API_BASE_URL}`
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const [visitDate, setVisitDate] = useState(currentDay);
    const [beginDayHour, setBeginDayHour] = useState(dayjs().hour(7).minute(0));
    const [endDayHour, setEndDayHour] = useState(dayjs().hour(20).minute(0));
    const [visitTime, setVisitTime] = useState(dayjs().hour(10).minute(0));
    const [worker, setWorker] = useState([]);
    const [worker1, setWorker1] = useState([]);
    const [client1, setClient1] = useState([]);
    const [client, setClient] = useState([]);
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [procedure1, setProcedure1] = useState([]);
    const [procedure, setProcedure] = useState("");
    const [price, setPrice] = useState();
    const [duration, setDuration] = useState();
    const [rooms, setRooms] = useState(["1","2","3","4","5"]);
    const [comment, setComment] = useState();
    const [sanitizedRoom,setSanitizedRoom] = React.useState(0);
    const [sanitizedComment, setSanitizedComment] = React.useState("");
    const [room, setRoom] = React.useState('');
    const [visits, setVisits] = useState([]);



    const loadVisits = () => {
        const weekDataToSend = {start: currentDay.toDate().toLocaleDateString(), end: currentDay.toDate().toLocaleDateString()}
        setVisits([]);
        fetch(`${api}/salon/visit/giveAllFromWeek`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            credentials:'include',
            body: JSON.stringify(weekDataToSend)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const allVisits = data.map(visit => ({id:visit.id,
                    borderColor: visit.borderColor,clientName: visit.clientName,color:visit.color,
                    comment:visit.comment,date:visit.date,duration:visit.duration,hour:visit.hour,
                    procedureName: visit.procedureName,roomNumber:visit.roomNumber,workerName:visit.workerName,
                    completed:visit.completed,price:visit.price, phoneNumber:visit.phoneNumber
                }));
                setVisits(allVisits);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    async function sleepToLoadVisits() {

        await sleep(400);
        if(addOneAfterAnother){
            setProcedure("");
            setVisitTime(visitTime.add(duration, 'minute'));
            setDuration(0);
            setPrice(0);
        }else{
            setVisitTime(dayjs().hour(12).minute(0));
            setWorker1("");
            setProcedure("");
            setClient("");
            setDuration(0);
            setPrice(0);
            setRoom(1)
            setComment("");
        }
        loadWorkers();
        loadVisits();
    }

    const editWorkingTime = () => {
        if(worker1.length === 0 || !beginDayHour || !endDayHour || !visitDate){
            setSnackBarOpen(true);
            return;
        }

        const dataToSend = {workerNameAndSurname: worker1, date: currentDay.toDate().toLocaleDateString(), timeStart: beginDayHour.hour()+":"+beginDayHour.minute(), timeEnd: endDayHour.hour()+":"+endDayHour.minute()}

        if(!isChecked){
            fetch(`${api}/salon/workerAvailability/saveWorkerAvailability`,{
                method: 'POST',
                headers:{'Content-Type': 'application/json'},
                credentials:'include',
                body: JSON.stringify(dataToSend)
            }).then(response => {
                if (!response.ok) {
                    console.log(response)
                }
            })
        }else{
            fetch(`${api}/salon/workerAvailability/saveWorkerAvailabilityForNext7Days`,{
                method: 'POST',
                headers:{'Content-Type': 'application/json'},
                credentials:'include',
                body: JSON.stringify(dataToSend)
            }).then(response => {
                if (!response.ok) {
                    console.log(response)
                }
            })
        }
        sleepToLoadVisits()

    }

    const addClientForm = (e) => {
        if (!clientName || !clientEmail || !clientPhoneNumber || !clientDescription) {
            setSnackBarOpen(true);
        }else{
            const sanitizedClientName = DOMPurify.sanitize(clientName);
            const sanitizedClientEmail = DOMPurify.sanitize(clientEmail);
            const sanitizedClientPhoneNumber = DOMPurify.sanitize(clientPhoneNumber);
            const sanitizedClientDescription = DOMPurify.sanitize(clientDescription);
            const clientAddData = {firstAndLastName: sanitizedClientName, email: sanitizedClientEmail, phoneNumber: sanitizedClientPhoneNumber, comment: sanitizedClientDescription}
            fetch(`${api}/salon/client/add`,{
                method: 'POST',
                headers:{'Content-Type': 'application/json'},
                body:JSON.stringify(clientAddData),
                credentials:'include'
            })
                .then(response => {
                    if (!response.ok) {
                        console.log(response)
                    }else{
                        fetch(`${api}/salon/client/getAllClients`,{
                            method: 'GET',
                            headers:{'Content-Type': 'application/json'},
                            credentials:'include'
                        })
                            .then(response => {
                                if (!response.ok) {
                                    fetch(`${api}/salon/client/getAllClients`,{
                                        method: 'GET',
                                        headers:{'Content-Type': 'application/json'},
                                        credentials:'include'
                                    })
                                        .then(response => {
                                            if (!response.ok) {
                                                throw new Error(`HTTP error! status: ${response.status}`);
                                            }
                                            return response.json();
                                        })
                                        .then(data => {
                                            const clientNames = data.map(client => client.nameandsurname);
                                            setClient1(clientNames);
                                        })
                                        .catch(error => {
                                            console.error('Error:', error);
                                        });
                                }
                                return response.json();
                            })
                            .then(data => {
                                const clientNames = data.map(client => client.nameandsurname);
                                setClient1(clientNames);
                            })
                            .catch(error => {
                                console.error('Error:', error);
                            });
                    }
                })
        }
        setClientName("");
        setClientEmail("");
        setClientPhoneNumber("");
        setClientDescription("");
    }
    const addVisitForm =(e)=>{
        if (!procedure || !duration || !worker1 || !client || !visitDate || !visitTime || !price) {
            setSnackBarOpen(true);
        }else{
            let day = visitDate.date(); // gets the day of the month
            let month = visitDate.month() + 1; // gets the month (0-11, hence the +1)
            let year = visitDate.year(); // gets the year
            const sanitizedWorker1 = DOMPurify.sanitize(worker1);
            const sanitizedClient = DOMPurify.sanitize(client);
            const sanitizedProcedure = DOMPurify.sanitize(procedure);
            const sanitizedPrice = DOMPurify.sanitize(price);
            const sanitizedDuration = DOMPurify.sanitize(duration);
            setSanitizedRoom(DOMPurify.sanitize(room));
            setSanitizedComment(DOMPurify.sanitize(comment));
            if(!sanitizedRoom){setSanitizedRoom(0);}
            if(!sanitizedComment){setSanitizedComment("");}
            let date = `${year}-${month}-${day}`;
            let time = visitTime.hour()+':'+visitTime.minute();
            const visitAddData = {date:date, time:time, workerNameAndSurname:sanitizedWorker1, clientFirstAndLastName:sanitizedClient, procedureName:sanitizedProcedure, price:sanitizedPrice, duration:sanitizedDuration, room:sanitizedRoom, comment:sanitizedComment}
            fetch(`${api}/salon/visit/add`,{
                method: 'POST',
                headers:{'Content-Type': 'application/json'},
                body:JSON.stringify(visitAddData),
                credentials:'include'
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                })
            sleepToLoadVisits();
        }
    }

    useEffect(() => {
        fetch(`${api}/user/authorize/userData`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        }).then(response => {
            if (!response.ok) {
                fetch(`${api}/user/authorize/userData`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'},
                    credentials: 'include'
                }).then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                }).then(data => {
                    setFirstName(data.firstName)
                    setLastName(data.lastName)
                    setEmail(data.email)
                })
            }
            return response.json();
        }).then(data => {
            setFirstName(data.firstName)
            setLastName(data.lastName)
            setEmail(data.email)
        })
        fetch(`${api}/salon/client/getAllClients`,{
            method: 'GET',
            headers:{'Content-Type': 'application/json'},
            credentials:'include'
        })
            .then(response => {
                if (!response.ok) {
                    fetch(`${api}/salon/client/getAllClients`,{
                        method: 'GET',
                        headers:{'Content-Type': 'application/json'},
                        credentials:'include'
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(`HTTP error! status: ${response.status}`);
                            }
                            return response.json();
                        })
                        .then(data => {
                            const clientNames = data.map(client => client.nameandsurname);
                            setClient1(clientNames);
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                }
                return response.json();
            })
            .then(data => {
                const clientNames = data.map(client => client.nameandsurname);
                setClient1(clientNames);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        fetch(`${api}/salon/procedure/getAllProcedures`,{
            method: 'GET',
            headers:{'Content-Type': 'application/json'},
            credentials:'include'
        })
            .then(response => {
                if (!response.ok) {
                    fetch(`${api}/salon/procedure/getAllProcedures`,{
                        method: 'GET',
                        headers:{'Content-Type': 'application/json'},
                        credentials:'include'
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(`HTTP error! status: ${response.status}`);
                            }
                            return response.json();
                        })
                        .then(data => {
                            const procedureInfo = data.map(procedure => ({
                                name: procedure.name,
                                price: procedure.price,
                                duration: procedure.duration,
                                description: procedure.description
                            }));
                            setProcedure1(procedureInfo);
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                }
                return response.json();
            })
            .then(data => {
                const procedureInfo = data.map(procedure => ({
                    name: procedure.name,
                    price: procedure.price,
                    duration: procedure.duration,
                    description: procedure.description
                }));
                setProcedure1(procedureInfo);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    const [workerInfo, setWorkerInfo] = useState([]);
    const loadWorkers = () => {
        fetch(`${api}/salon/workerAvailability/getWorkersAndTheirAvailabilityForPeriod`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            credentials:'include',
            body: JSON.stringify({start: currentDay.toDate().toLocaleDateString(), end: currentDay.toDate().toLocaleDateString()})
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
            .then(data => {
                var workerNames = data.workers.map(worker => ({ id: worker.id, lastName: worker.nameandsurname, color:worker.color,borderColor:worker.borderColor, availabilityStart: "07:00",availabilityEnd: "20:00" }));
                setWorkerInfo(workerNames);
                data.workerAvailabilities[0].forEach(availability => {
                    const worker = workerNames.find(worker => worker.lastName === availability.workerName);
                    var st = availability.startTime.split(":");
                    var en = availability.endTime.split(":");
                    if (worker) {
                        worker.availabilityStart = st[0]+":"+st[1];
                        worker.availabilityEnd = en[0]+":"+en[1];
                    }
                });
                const workerLastNames = workerNames.map(worker => worker.lastName);
                setWorker(workerLastNames);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    useEffect(loadWorkers,[]);
    useEffect(() => {
        loadVisits();
    }, [currentDay]);

    const [redLineStyleTop, setRedLineStyleTop] = useState(0);
    useEffect(() => {
        const calculatePosition = () => {
            const now = new Date();
            const minutesSinceMidnight = now.getHours() * 60 + now.getMinutes();
            const position = (minutesSinceMidnight - 420) * 2;
            if(position<0 || position>1680){
                setRedLineStyleTop(0);
            }else{
                setRedLineStyleTop(position);
            }
        };

        // Call once immediately
        calculatePosition();

        // Then set up the interval
        const intervalId = setInterval(calculatePosition, 60000); // 60000 ms = 1 minute

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);
    return (
        <Box>
            <NavBar/>
            <div className="pageContainer">
                <div className="dashBoardContainer">
                    <div className="leftDashboardBlock">
                        <div className="dashboardProfileInfo" style={{border: "3px solid #f0f0f0"}}>
                            <Button
                                variant="contained"
                                sx={{
                                    borderRadius: "100px", padding: "5px", backgroundColor: "#EEEDEB",
                                    '&:hover': {
                                        backgroundColor: '#DDDDDD',
                                    },
                                    [theme.breakpoints.down(768)]: {
                                        borderRadius: "100px", padding: "3px", backgroundColor: "#EEEDEB",
                                        '&:hover': {
                                            backgroundColor: '#DDDDDD',
                                        },
                                    },
                                }}
                                onClick={() => {
                                    navigate('/settings')
                                }}
                            >
                                <Avatar sx={{bgcolor: deepOrange[500], width: "100px", height: "100px",
                                    [theme.breakpoints.down(768)]: {
                                        width: "70px", height: "70px"
                                    },
                                }}>
                                    {firstName[0] + lastName[0]}
                                </Avatar>
                            </Button>
                            <Box sx={{
                                marginTop: "20px", fontSize: "20px",
                                    [theme.breakpoints.down(768)]: {
                                        marginTop: "15px",
                                        fontSize: "15px"
                                    },

                            }}>{firstName} {lastName}</Box>
                            <div style={{
                                marginTop: "5px", fontSize: "12px", marginBottom: "20px",
                                    [theme.breakpoints.down(768)]: {
                                marginTop: "5px", fontSize: "10px", marginBottom: "20px"
                            },
                            }}>{email}</div>
                            <div className="optionsContainer">
                                <OptionInProfile
                                    text="Dodaj wizytę"
                                    onClick={changeAddVisit}
                                    param={addVisit}
                                    icon={ViewAgendaIcon}
                                />
                                <OptionInProfile
                                    text="Widok dnia"
                                    onClick={seePageDayView}
                                    param={pageDayView}
                                    icon={PageviewIcon}
                                />
                            </div>
                            <div className="optionsContainer">
                                <OptionInProfile
                                    text="Dodaj klienta"
                                    onClick={changeAddClient}
                                    param={addClient}
                                    icon={PersonAddAlt1Icon}
                                />
                                <OptionInProfile
                                    text="Dodaj jedną po drugiej"
                                    onClick={changeAddOneAfterAnother}
                                    param={addOneAfterAnother}
                                    icon={QueueIcon}
                                />
                            </div>
                            <div className="optionsContainer">
                                <OptionInProfile
                                    text="Ustaw czas pracy"
                                    onClick={changeEditWorkTime}
                                    param={editWorkTime}
                                    icon={AccessTimeIcon}
                                />
                            </div>
                            {/*<div className="optionsContainer">*/}
                            {/*    <OptionInProfile*/}
                            {/*        text="STATYSTYKI"*/}
                            {/*        onClick={showMyStatistics}*/}
                            {/*        param={showStatistics}*/}
                            {/*        icon={AnalyticsIcon}*/}
                            {/*    />*/}
                            {/*    <OptionInProfile*/}
                            {/*        text="MOJE ZADANIA"*/}
                            {/*        onClick={showMyToDo}*/}
                            {/*        param={showToDo}*/}
                            {/*        icon={FormatListBulletedIcon}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="middleDashboardBlock">
                        <div style={{
                            marginTop: "20px",
                            marginBottom: "20px",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            paddingLeft: "50px",
                            paddingRight: "50px",
                            paddingBottom: "20px",
                            paddingTop: "20px",
                            borderRadius: "15px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            border: "2px solid #f0f0f0"
                        }}>
                            {currentDay.locale('pl').format('D MMMM')}
                        </div>
                        <div className="dashBoardCalendar">
                            <div style={{
                                gridColumn: "1",
                                borderRight: "1px solid",
                                borderBottom: "1px solid",
                                left: "0",
                                position: "sticky",
                                zIndex: "11",
                                backgroundColor: "#faf9f9"
                            }}></div>

                            <div className="columnIndex"
                                 style={{position: "sticky", top: "0", zIndex: "10", borderBottom: "1px solid"}}>


                                {workerInfo.map((worker, index) => (
                                    <div key={worker.id} className="columnName" style={{gridColumn: index + 1}}>
                                        {worker.lastName}
                                        <br />
                                        {worker.availabilityStart} - {worker.availabilityEnd}
                                    </div>
                                ))}
                                {Array.from({length: 7 - workerInfo.length}, (_, index) => (
                                    <div
                                        key={workerInfo.length + index + 1}
                                        className="columnName"
                                        style={{gridColumn: workerInfo.length + index + 1}}
                                    >
                                    </div>
                                ))}

                            </div>
                            <div className="dashboardCalendarHours">
                                {hours}
                            </div>
                            <div className="dashboardCalendarGrid" style={{position: "relative"}}>
                                <div style={{
                                    top: redLineStyleTop + "px",
                                }} className="red-line"></div>
                                {workerInfo.map((worker, index) => {
                                    // Filter the visits for the current worker
                                    const workerVisits = visits.filter(visit => visit.workerName === worker.lastName);
                                    return (
                                        <div
                                            key={worker.id}
                                            className="dashboardWorkerColumn"
                                            style={{gridColumn: index + 1}}
                                        >
                                            {
                                                Array.from({length: 840}, (_, index) => (
                                                    <div key={index} className="dashboardGridBackground"></div>
                                                ))
                                            }
                                            <div className="workerColumn">
                                                {workerVisits.map(visit => (
                                                    <DashboardVisit
                                                        key={visit.id}
                                                        borderColor={visit.borderColor}
                                                        color={visit.color}
                                                        startTime={visit.hour}
                                                        client={visit.clientName}
                                                        worker={visit.workerName}
                                                        procedure={visit.procedureName}
                                                        comment={visit.comment}
                                                        room={visit.roomNumber}
                                                        durationInMinutes={visit.duration}
                                                        price={visit.price}
                                                        date={visit.date}
                                                        completed={visit.completed}
                                                        id={visit.id}
                                                        onActionComplete={sleepToLoadVisits}
                                                        phoneNumber={visit.phoneNumber}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    );
                                })}
                                {Array.from({length: 7 - workerInfo.length}, (_, index) => (
                                    <div
                                        key={workerInfo.length + index + 1}
                                        className="dashboardWorkerColumn"
                                        style={{gridColumn: workerInfo.length + index + 1}}
                                    >
                                        {
                                            Array.from({length: 840}, (_, index) => (
                                                <div key={index} className="dashboardGridBackground"></div>
                                            ))
                                        }
                                        <div className="workerColumn">
                                            {/* No visits in this column */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="rightDashboardBlock">
                    <div className="dashboardProfileInfo">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar value={currentDay} onChange={(newValue) => {
                                    setCurrentDay(newValue);
                                    setVisitDate(newValue);
                                }}
                                sx={{
                                    width: "70%",
                                    height: "100%",

                                }}/>
                            </LocalizationProvider>
                            {addVisit &&
                                <FormControl>
                                    <div style={{
                                        marginRight: "auto",
                                        marginLeft: "auto",
                                        fontWeight: "bold"
                                    }}>Dodaj wizytę
                                    </div>
                                    <div className="formVisit">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <div className="formVisitComponent">
                                                <DemoContainer components={['DatePicker']} sx={{marginRight: 2}}>
                                                    <DatePicker label={t("calendar.date")} sx={{width: '100%'}}
                                                                required
                                                                value={visitDate}
                                                                onChange={(newValue) => {
                                                                    setVisitDate(newValue);
                                                                }}/>
                                                </DemoContainer>
                                                <DemoContainer components={['TimePicker']}>
                                                    <TimePicker
                                                        required
                                                        clearable
                                                        value={visitTime}
                                                        ampm={false}
                                                        label={t("calendar.time")}
                                                        sx={{width: '100%'}}
                                                        onChange={(newValue) => {
                                                            setVisitTime(newValue);
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </div>
                                            <div className="formVisitComponent">
                                                <Autocomplete
                                                    required
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={worker}
                                                    value={worker1}
                                                    sx={{width: 300, marginTop: 2}}
                                                    renderInput={(params) => <TextField {...params}
                                                                                        label={t("calendar.worker")}/>}
                                                    onChange={(event, newValue) => {
                                                        setWorker1(newValue);
                                                    }}
                                                />

                                            </div>
                                            <div className="formVisitComponent">
                                                <Autocomplete
                                                    freeSolo
                                                    id="free-solo-demo"
                                                    options={client1}
                                                    value={client}
                                                    sx={{width: 300, marginTop: 2}}
                                                    renderInput={(params) => <TextField {...params}
                                                                                        label={t("calendar.client")}/>}
                                                    onChange={(event, newValue) => {
                                                        setClient(newValue);
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        setClient(newInputValue);
                                                    }}
                                                />

                                            </div>
                                            <div className="formVisitComponent">
                                                <Autocomplete
                                                    required
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={procedure1.map(procedure => procedure.name)}
                                                    sx={{width: 300, marginTop: 2}}
                                                    renderInput={(params) => <TextField {...params}
                                                                                        label={t("calendar.procedure")}/>}
                                                    onChange={(event, newValue) => {
                                                        setProcedure(newValue);
                                                        procedure1.forEach(procedure => {
                                                            if (procedure.name === newValue) {
                                                                setPrice(procedure.price)
                                                                setDuration(procedure.duration)
                                                                setComment(procedure.description)
                                                            }
                                                        })
                                                    }}
                                                    value={procedure}
                                                />
                                            </div>
                                            <div className="formVisitComponent">
                                                <TextField
                                                    sx={{width: 300, marginTop: 2, marginRight: 2}}
                                                    id="outlined-number"
                                                    label={t("calendar.price")}
                                                    type="number"
                                                    value={price}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(e) => setPrice(e.target.value)}
                                                />
                                                <TextField
                                                    sx={{width: 300, marginTop: 2}}
                                                    required
                                                    id="outlined-number"
                                                    label={t("calendar.duration")}
                                                    value={duration}
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(e) => setDuration(e.target.value)}
                                                />
                                            </div>
                                            <div className="formVisitComponent">
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={rooms}
                                                    value={room}
                                                    sx={{marginTop: 2}}
                                                    renderInput={(params) => <TextField {...params}
                                                                                        label={t("calendar.room")}/>}
                                                    onChange={(event, newValue) => {
                                                        setRoom(newValue);
                                                    }}
                                                />
                                            </div>
                                            <div className="formVisitComponent">
                                                <TextField
                                                    disablePortal
                                                    sx={{marginTop: 2}}
                                                    id="outlined-multiline-static"
                                                    label={t("calendar.comment")}
                                                    value={comment}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    multiline
                                                    rows={3}
                                                    onChange={(e) => setComment(e.target.value)}
                                                />
                                            </div>
                                            <div className="formVisitComponent"><br/></div>
                                            <Button variant="contained"
                                                    endIcon={<SendIcon/>}
                                                    onClick={addVisitForm}
                                            >
                                                {t("calendar.addVisit")}
                                            </Button>
                                            <div className="formVisitComponent"><br/></div>
                                        </LocalizationProvider>
                                    </div>
                                </FormControl>
                            }
                        {addClient &&

                            <FormControl>
                                <div style={{
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    fontWeight: "bold"
                                }}>Dodaj klienta</div>
                                <div className="formVisit">
                                    <div className="formVisitComponent">

                                        <TextField
                                            autoFocus
                                            id="outlined-number"
                                            label={t("SalonManager.clientName")}
                                            type="text"
                                            margin="dense"
                                            value={clientName}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setClientName(e.target.value)}
                                        />
                                    </div>
                                    <div className="formVisitComponent" style={{marginTop: 3}}>
                                        <TextField
                                            autoFocus
                                            id="outlined-number"
                                            label={t("SalonManager.email")}
                                            type="text"
                                            margin="dense"
                                            value={clientEmail}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setClientEmail(e.target.value)}
                                        />
                                        <TextField
                                            autoFocus
                                            id="outlined-number"
                                            label={t("SalonManager.phoneNumber")}
                                            type="number"
                                            margin="dense"
                                            value={clientPhoneNumber}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setClientPhoneNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className="formVisitComponent">
                                        <TextField
                                            autoFocus
                                            id="outlined-number"
                                            label={t("SalonManager.description")}
                                            type="text"
                                            margin="dense"
                                            value={clientDescription}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setClientDescription(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <Button variant="contained"
                                        endIcon={<SendIcon/>}
                                        onClick={addClientForm}
                                        sx={{
                                            marginRight: "auto",
                                            marginLeft: "auto",
                                            maxWidth: "40%"}}

                                >
                                    {t("calendar.addVisit")}
                                </Button>
                            </FormControl>
                        }
                        {editWorkTime &&
                            <FormControl>
                                <div style={{
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    fontWeight: "bold",
                                }}>Ustaw czas pracy
                                </div>
                                <div className="formVisit">
                                    <div className="formVisitComponent">
                                        <Autocomplete
                                            required
                                            disablePortal
                                            id="combo-box-demo"
                                            options={worker}
                                            value={worker1}
                                            sx={{width: 600, marginTop: 2,
                                                [theme.breakpoints.down('sm')]: {
                                                width: "76%"
                                                },
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                                                label={t("calendar.worker")}/>}
                                            onChange={(event, newValue) => {
                                                setWorker1(newValue);
                                            }}
                                        />

                                    </div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <div className="formVisitComponent">
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker label={t("calendar.date")} sx={{width: 600,
                                                    [theme.breakpoints.down('sm')]: {
                                                        width: "50%"
                                                    },}}
                                                            required
                                                            value={visitDate}
                                                            onChange={(newValue) => {
                                                                setVisitDate(newValue);
                                                            }}/>
                                            </DemoContainer>
                                        </div>
                                        <div className="formVisitComponent">
                                            <DemoContainer components={['DatePicker']} sx={{marginRight: 2}}>
                                                <TimePicker
                                                    required
                                                    clearable
                                                    value={beginDayHour}
                                                    ampm={false}
                                                    label={t("calendar.time")}
                                                    sx={{width: '50%'}}
                                                    onChange={(newValue) => {
                                                        setBeginDayHour(newValue);
                                                    }}
                                                />
                                            </DemoContainer>
                                            <DemoContainer components={['TimePicker']}>
                                                <TimePicker
                                                    required
                                                    clearable
                                                    value={endDayHour}
                                                    ampm={false}
                                                    label={t("calendar.time")}
                                                    sx={{width: '50%'}}
                                                    onChange={(newValue) => {
                                                        setEndDayHour(newValue);
                                                    }}
                                                />
                                            </DemoContainer>
                                        </div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={(event) => setIsChecked(event.target.checked)}
                                                />
                                            }
                                            label="ustaw zmiany na 7 dni do przodu"
                                        />
                                    </LocalizationProvider>
                                </div>

                                 <Button variant="contained"
                                            endIcon={<SendIcon/>}
                                            onClick={editWorkingTime}
                                            sx={{
                                                marginRight: "auto",
                                                marginLeft: "auto",
                                                maxWidth: "40%",
                                                marginTop: 2
                                            }}

                                    >
                                        Ustaw czas pracy
                                 </Button>



                            </FormControl>
                        }
                    </div>

                    </div>
                </div>
            </div>
            <BootstrapDialog
                onClose={seePageDayView}
                aria-labelledby="customized-dialog-title"
                open={pageDayView}
                fullWidth={true}
                maxWidth={false}
                sx={{width: "90%", height: "100%", margin: "auto"}}
            >
                <div style={{paddingLeft: "15px", paddingRight: "15px"}}>


                    <div style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        paddingLeft: "50px",
                        paddingRight: "50px",
                        paddingBottom: "20px",
                        paddingTop: "20px",
                        borderRadius: "15px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        border: "2px solid #f0f0f0",
                        position: "relative"
                    }}>
                        {currentDay.locale('pl').format('D MMMM')}
                        <IconButton
                            aria-label="close"
                            onClick={seePageDayView}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: "13%",

                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon fontSize="large"/>
                        </IconButton>
                        <div style={{
                            position: 'absolute',
                            right: 70,
                            top: "24%",
                        }}>
                            <Tools changeWeek={sleepToLoadVisits}/>
                        </div>
                    </div>
                    <div className="dashBoardCalendarInDayView">
                        <div style={{
                            gridColumn: "1",
                            borderRight: "1px solid",
                            borderBottom: "1px solid",
                            position: "sticky",
                            left: "0",
                            zIndex: "11",
                            backgroundColor: "#faf9f9"
                        }}></div>
                        <div className="columnIndexInDayView"
                             style={{position: "sticky", top: "0", zIndex: "10", borderBottom: "1px solid"}}>


                            {workerInfo.map((worker, index) => (
                                <div key={worker.id} className="columnName" style={{gridColumn: index + 1}}>
                                    {worker.lastName}
                                    <br/>
                                    {worker.availabilityStart} - {worker.availabilityEnd}
                                </div>
                            ))}
                            {Array.from({length: 7 - workerInfo.length}, (_, index) => (
                                <div
                                    key={workerInfo.length + index + 1}
                                    className="columnName"
                                    style={{gridColumn: workerInfo.length + index + 1}}
                                >
                                </div>
                            ))}

                        </div>
                        <div className="dashboardCalendarHours">
                            {hours}
                        </div>
                        <div className="dashboardCalendarGridInDayView">
                            <div style={{
                                top: redLineStyleTop+"px",
                            }} className="red-line"></div>
                            {workerInfo.map((worker, index) => {
                                // Filter the visits for the current worker
                                const workerVisits = visits.filter(visit => visit.workerName === worker.lastName);

                                return (
                                    <div
                                        key={worker.id}
                                        className="dashboardWorkerColumn"
                                        style={{gridColumn: index + 1}}
                                    >
                                        {
                                            Array.from({length: 840}, (_, index) => (
                                                <div key={index} className="dashboardGridBackground"></div>
                                            ))
                                        }
                                        <div className="workerColumn">
                                            {workerVisits.map(visit => (
                                                <DashboardVisit
                                                    key={visit.id}
                                                    borderColor={visit.borderColor}
                                                    color={visit.color}
                                                    startTime={visit.hour}
                                                    client={visit.clientName}
                                                    worker={visit.workerName}
                                                    procedure={visit.procedureName}
                                                    comment={visit.comment}
                                                    room={visit.roomNumber}
                                                    durationInMinutes={visit.duration}
                                                    price={visit.price}
                                                    date={visit.date}
                                                    completed={visit.completed}
                                                    id={visit.id}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                );
                            })}
                            {Array.from({length: 7 - workerInfo.length}, (_, index) => (
                                <div
                                    key={workerInfo.length + index + 1}
                                    className="dashboardWorkerColumn"
                                    style={{gridColumn: workerInfo.length + index + 1}}
                                >
                                    {
                                        Array.from({length: 840}, (_, index) => (
                                            <div key={index} className="dashboardGridBackground"></div>
                                        ))
                                    }
                                    <div className="workerColumn">
                                        {/* No visits in this column */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </BootstrapDialog>
            <div>
                <Snackbar
                    open={snackBarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackBarClose}
                    message="Uzupełnij wszystkie wymagane pola"
                />
            </div>
        </Box>
    );
}