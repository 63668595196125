import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NavBar from "../generalComponents/NavBar";
import {useTranslation} from "react-i18next";
import Visits from "./Visits";
import Workers from "./Workers";
import Clients from "./Clients";
import Procedures from "./Procedures";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SalonInfo(props) {
    const [t] = useTranslation('global');
    const localizedTextsMap = {
        // Root
        noRowsLabel: 'Brak danych',
        noResultsOverlayLabel: 'Nie znaleziono wyników.',

        // Density selector toolbar button text
        toolbarDensity: 'Wysokość rzędu',
        toolbarDensityLabel: 'Wysokość rzędu',
        toolbarDensityCompact: 'Kompakt',
        toolbarDensityStandard: 'Standard',
        toolbarDensityComfortable: 'Komfort',

        // Columns selector toolbar button text
        toolbarColumns: 'Kolumny',
        toolbarColumnsLabel: 'Zaznacz kolumny',

        // Filters toolbar button text
        toolbarFilters: 'Filtry',
        toolbarFiltersLabel: 'Pokaż filtry',
        toolbarFiltersTooltipHide: 'Ukryj filtry',
        toolbarFiltersTooltipShow: 'Pokaż filtry',
        toolbarFiltersTooltipActive: (count) => `Liczba aktywnych filtrów: ${count}`,

        // Quick filter toolbar field
        toolbarQuickFilterPlaceholder: 'Wyszukaj…',
        toolbarQuickFilterLabel: 'Szukaj',
        toolbarQuickFilterDeleteIconLabel: 'Wyczyść',

        // Export selector toolbar button text
        toolbarExport: 'Eksportuj',
        toolbarExportLabel: 'Eksportuj',
        toolbarExportCSV: 'Pobierz jako plik CSV',
        toolbarExportPrint: 'Drukuj',
        toolbarExportExcel: 'Pobierz jako plik Excel',

        // Columns management text
         columnsManagementSearchTitle: 'Szukaj',
        // columnsManagementNoColumns: 'No columns',
         columnsManagementShowHideAllText: 'Pokaż/Ukryj wszystkie',
         columnsManagementReset: 'Resetuj',

        // Filter panel text
        filterPanelAddFilter: 'Dodaj filtr',
        filterPanelRemoveAll: 'Usuń wszystkie',
        filterPanelDeleteIconLabel: 'Usuń',
        filterPanelLogicOperator: 'Operator logiczny',
        filterPanelOperator: 'Operator',
        filterPanelOperatorAnd: 'I',
        filterPanelOperatorOr: 'Lub',
        filterPanelColumns: 'Kolumny',
        filterPanelInputLabel: 'Wartość',
        filterPanelInputPlaceholder: 'Filtrowana wartość',

        // Filter operators text
        filterOperatorContains: 'zawiera',
        filterOperatorEquals: 'równa się',
        filterOperatorStartsWith: 'zaczyna się od',
        filterOperatorEndsWith: 'kończy się na',
        filterOperatorIs: 'równa się',
        filterOperatorNot: 'różne',
        filterOperatorAfter: 'większe niż',
        filterOperatorOnOrAfter: 'większe lub równe',
        filterOperatorBefore: 'mniejsze niż',
        filterOperatorOnOrBefore: 'mniejsze lub równe',
        filterOperatorIsEmpty: 'jest pusty',
        filterOperatorIsNotEmpty: 'nie jest pusty',
        filterOperatorIsAnyOf: 'jest jednym z',
        // 'filterOperator=': '=',
        // 'filterOperator!=': '!=',
        // 'filterOperator>': '>',
        // 'filterOperator>=': '>=',
        // 'filterOperator<': '<',
        // 'filterOperator<=': '<=',

        // Header filter operators text
        headerFilterOperatorContains: 'Zawiera',
        headerFilterOperatorEquals: 'Równa się',
        headerFilterOperatorStartsWith: 'Zaczyna się od',
        headerFilterOperatorEndsWith: 'Kończy się na',
        // headerFilterOperatorIs: 'Is',
        headerFilterOperatorNot: 'Niepuste',
        // headerFilterOperatorAfter: 'Is after',
        // headerFilterOperatorOnOrAfter: 'Is on or after',
        // headerFilterOperatorBefore: 'Is before',
        // headerFilterOperatorOnOrBefore: 'Is on or before',
        // headerFilterOperatorIsEmpty: 'Is empty',
        // headerFilterOperatorIsNotEmpty: 'Is not empty',
        // headerFilterOperatorIsAnyOf: 'Is any of',
        // 'headerFilterOperator=': 'Equals',
        // 'headerFilterOperator!=': 'Not equals',
        // 'headerFilterOperator>': 'Greater than',
        // 'headerFilterOperator>=': 'Greater than or equal to',
        // 'headerFilterOperator<': 'Less than',
        // 'headerFilterOperator<=': 'Less than or equal to',

        // Filter values text
        filterValueAny: 'dowolny',
        filterValueTrue: 'prawda',
        filterValueFalse: 'fałsz',

        // Column menu text
        columnMenuLabel: 'Menu',
        columnMenuShowColumns: 'Pokaż wszystkie kolumny',
        columnMenuManageColumns: 'Zarządzaj kolumnami',
        columnMenuFilter: 'Filtr',
        columnMenuHideColumn: 'Ukryj',
        columnMenuUnsort: 'Anuluj sortowanie',
        columnMenuSortAsc: 'Sortuj rosnąco',
        columnMenuSortDesc: 'Sortuj malejąco',

        // Column header text
        columnHeaderFiltersTooltipActive: (count) => `Liczba aktywnych filtrów: ${count}`,
        columnHeaderFiltersLabel: 'Pokaż filtry',
        columnHeaderSortIconLabel: 'Sortuj',

        // Rows selected footer text
        footerRowSelected: (count) => `Liczba wybranych wierszy: ${count.toLocaleString()}`,

        // Total row amount footer text
        footerTotalRows: 'Łączna liczba wierszy:',

        // Total visible row amount footer text
        footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} z ${totalCount.toLocaleString()}`,

        // Checkbox selection text
        checkboxSelectionHeaderName: 'Pole wyboru',
        checkboxSelectionSelectAllRows: 'Zaznacz wszystkie wiersze',
        checkboxSelectionUnselectAllRows: 'Odznacz wszystkie wiersze',
        checkboxSelectionSelectRow: 'Zaznacz wiersz',
        checkboxSelectionUnselectRow: 'Odznacz wiersz',

        // Boolean cell text
        booleanCellTrueLabel: 'tak',
        booleanCellFalseLabel: 'nie',

        // Actions cell more text
        actionsCellMore: 'więcej',

        // Column pinning text
        pinToLeft: 'Przypnij do lewej',
        pinToRight: 'Przypnij do prawej',
        unpin: 'Odepnij',

        // Tree Data
        treeDataGroupingHeaderName: 'Grupa',
        treeDataExpand: 'pokaż elementy potomne',
        treeDataCollapse: 'ukryj elementy potomne',

        // Grouping columns
        groupingColumnHeaderName: 'Grupa',
        groupColumn: (name) => `Grupuj według ${name}`,
        unGroupColumn: (name) => `Rozgrupuj ${name}`,

        // Master/detail
        // detailPanelToggle: 'Detail panel toggle',
        expandDetailPanel: 'Rozwiń',
        collapseDetailPanel: 'Zwiń',

        // Row reordering text
        rowReorderingHeaderName: 'Porządkowanie wierszy',

        // Aggregation
         aggregationMenuItemHeader: 'Agregacja',
         aggregationFunctionLabelSum: 'suma',
         aggregationFunctionLabelAvg: 'wartość średnia',
         aggregationFunctionLabelMin: 'wartość najmniejsza',
         aggregationFunctionLabelMax: 'wartość największa',
         aggregationFunctionLabelSize: 'rozmiar',
    };
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return(
        <Box>
            <NavBar/>
            <div className="pageContainer">
                <Box sx={{ width: '100%',}}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label= {t("SalonManager.visits")} sx={{width:'15vw'}} {...a11yProps(0)} />
                            <Tab label={t("SalonManager.clients")} sx={{width:'15vw'}} {...a11yProps(1)} />
                            <Tab label={t("SalonManager.workers")} sx={{width:'15vw'}} {...a11yProps(2)} />
                            <Tab label={t("SalonManager.procedures")} sx={{width:'15vw'}} {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Visits localText={localizedTextsMap} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Clients localText={localizedTextsMap} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Workers localText={localizedTextsMap} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <Procedures localText={localizedTextsMap}/>
                    </CustomTabPanel>
                </Box>
            </div>
        </Box>
);
}