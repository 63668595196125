import NavBar from "../generalComponents/NavBar";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
export default function Home() {
    return (
        <div>
            <NavBar />
            <div className="pageContainer">
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    flexDirection: "column"
                }}>
                    <ReportProblemIcon sx={{width: "200px", fontSize: "200px"}}/>
                    <h1>Na stronie występują jakieś problemy?</h1>
                    <p>Zwracam się z prośbą o opisanie problemu i wysłanie informacji na maila:</p>
                    <h3>m.truszczynski04@gmail.com</h3>
                </div>
            </div>
        </div>
    );
}