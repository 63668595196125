// noinspection JSCheckFunctionSignatures

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import '../../css/style.scss';
import {useTranslation} from "react-i18next";
import {Alert, Container, Dialog, DialogContent, DialogTitle, IconButton, styled, Typography} from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import {useState} from "react";
import DOMPurify from 'dompurify';
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from '../../config/config';

export default function LoginForm() {
    const [t] = useTranslation('global');
    const [open, setOpen] = React.useState(false);
    const [currentResetPasswordMassage, setCurrentResetPasswordMassage] = React.useState('');
    const [emailToReset, setEmailToReset] = useState('');
    var finalEmail = '';
    const handleClickOpen = () => {
        setCurrentResetPasswordMassage(t("loginForm.resetPasswordInfoBody"));
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setEmailToReset('');
    };
    const handleResetPassword = () =>{
        const sanitizedEmail = DOMPurify.sanitize(finalEmail);
        console.log(sanitizedEmail);
        fetch(`${api}/user/authenticate/reset-password`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            credentials:'include',
            body: JSON.stringify(sanitizedEmail)
        })
        setCurrentResetPasswordMassage(t("loginForm.emailHasBeenSent"));
    }
    const BootstrapDialog = ({ open, handleClose }) => {
        const [emailToReset, setEmailToReset] = useState('');
        return (
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                {/* ... */}
                <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                    {t("loginForm.resetPasswordInfoHeader")}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {currentResetPasswordMassage}
                    </Typography>
                    <div className="formVisit">
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="outlined-basic"
                            value={emailToReset}
                            variant="outlined"
                            name="name"
                            label={t("SalonManager.email")}
                            type="email"
                            fullWidth
                            onChange={(e) => {
                                setEmailToReset(e.target.value);
                                finalEmail = e.target.value;
                            }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleResetPassword}>
                        {t("loginForm.resetPassword")}
                    </Button>
                </DialogActions>
                {/* ... */}
            </Dialog>
        );
    };
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const api = `${API_BASE_URL}`
    const navigate = useNavigate();
    const handleClick =(e)=>{
        e.preventDefault()
        const sanitizedLogin = DOMPurify.sanitize(login);
        const sanitizedPassword = DOMPurify.sanitize(password);
        if (!sanitizedPassword || !sanitizedLogin) {
            setAlertMessage(t("loginForm.warningMessage"));
            setAlertType('warning');
            setShowAlert(true);
            return;
        }
        if(sanitizedLogin!==login || sanitizedPassword!==password){
            setAlertMessage(t("loginForm.sanitizeDataMessage"));
            setAlertType('warning');
            setShowAlert(true);
            return;
        }
        const loginData = {email:sanitizedLogin,password:sanitizedPassword}
        fetch(`${api}/user/authenticate/login`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body:JSON.stringify(loginData),
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setAlertType('success')
                setAlertMessage(t("loginForm.successMessage"))
                setShowAlert(true);
                navigate('/calendar')
            })
            .catch(error => {
                setAlertMessage(t("loginForm.errorMessage"))
                setAlertType('error')
                setShowAlert(true);
            });
    }
    // noinspection JSCheckFunctionSignatures
    return (
        <Container>
            <div className="loginBox">
                <Button
                    onClick={handleClickOpen}
                    sx={{
                        borderRadius: 100,
                        padding: 2,
                        position: 'absolute', // Set position to absolute
                        top: 10, // Set top to 0
                        left: 10, // Set left to 0
                    }}
                >
                    <HelpIcon fontSize="large" />
                </Button>
                <BootstrapDialog
                    handleClose={handleClose}
                    open={open}
                >
                </BootstrapDialog>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {m: 1, width: '25ch'},
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        {showAlert &&
                            <Alert severity={alertType}
                                   variant="outlined"
                            ><b>{alertMessage}</b></Alert>
                        }
                    </div>
                    <div className="textField">
                        <TextField
                            sx={{
                                opacity:'1',
                                }}
                                required
                                type="email"
                                id="filled-required"
                                variant="filled"
                                label={t("loginForm.login")}
                                value={login}
                                onChange={(e) => setLogin(e.target.value)}
                            />
                        </div>
                        <div className="textField">
                            <TextField
                                sx={{
                                    opacity:'1',
                                }}
                                required
                                id="filled-password-input"
                                variant="filled"
                                label={t("loginForm.password")}
                                type="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="submitButton">
                            <Button
                                variant="contained"
                                type="submit"
                                size="large"
                                onClick={handleClick}
                            >
                                {t("loginForm.submit")}
                            </Button>
                        </div>
                </Box>
            </div>
        </Container>
    );
}