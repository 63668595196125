import * as React from 'react';
import Button from '@mui/material/Button';
import {API_BASE_URL} from "../../config/config";
import {useTranslation} from "react-i18next";
import LinkIcon from '@mui/icons-material/Link';
import TextField from '@mui/material/TextField';
export default function SalonAdminSettings() {
    const api = `${API_BASE_URL}`
    const [t] = useTranslation('global');
    const [registerLink, setRegisterLink] = React.useState('');
    const generateInvitationLink = () => {
        fetch(`${api}/user/registerToken/generate`,{
            method: 'GET',
            headers:{'Content-Type': 'application/json'},
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.text();  // First get the response as text
            })
            .then(text => {
                setRegisterLink(`https://atoll.website/register/${text}`);  // Then set the response as a link
            })
    };
    return (
        <div>
            <div>
                <Button onClick={generateInvitationLink} variant="contained" endIcon={<LinkIcon />}>
                    {t("managePageAdmin.generateRegistrationLink")}
                </Button>
                <div>{registerLink}</div>
            </div>
        </div>
    );
}